import { useState } from "react";
import firebase from "../plugins/firebase";
import { db } from "../helpers/firebase";
import { UserModel } from "../models";
import { setRoleId } from "../plugins/store/userSlice";
import { userRoles } from "../consts/userRoles";

const auth = firebase.auth();

const initialFormValues = {
  email: "",
  password: "",
  formSubmitted: false,
  success: false,
};

export const useFormControls = () => {
  // We'll update "values" as the form updates
  const [values, setValues] = useState(initialFormValues);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    for (const [key, value] of Object.entries(fieldValues)) {
      temp[key] = value ? "" : "This field is required.";
    }

    if ("email" in fieldValues) {
      //temp.email = fieldValues.email ? "" : "This field is required."
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    setErrors({
      ...temp,
    });
  };
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("reduxState");
    if (formIsValid()) {
      auth
        .signInWithEmailAndPassword(values.email, values.password)
        .then((data) => {
          setLoggedIn(true);
        })
        .catch(function (error) {
          let errorCode = error.code;
          let errorMessage = error.message;

          setLoginError(errorMessage);
          setErrorOpen(true);
          console.log(errorCode, errorMessage);
        });
    }
  };
  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.email &&
      fieldValues.password &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    loginError,
    errorOpen,
    setErrorOpen,
    loggedIn,
  };
};
