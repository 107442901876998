import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import {
  Avatar,
  CircularProgress,
  colors,
  Container,
  Grid,
  Hidden,
  Paper,
  Snackbar,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { db } from "../helpers/firebase";
import { UserModel } from "../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "@material-ui/lab";
import { typeOfClearances } from "../consts/typeOfClearances";
import { educationLevels } from "../consts/educationLevels";
import QRCode from "react-qr-code";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { APP } from "../consts/app";
import { getDateTime } from "../utiles/time-utiles";

const styles = (theme) => ({
  container: {
    margin: "20px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    padding: "25px",
    marginBottom: "15px",
    textAlign: "center",
    background: `${theme.palette.primary.main}`,
  },
  qrCodeContainer: {
    padding: "25px 25px 20px",
    marginBottom: "15px",
    textAlign: "center",
    background: "#fff",
  },
  avatar: {
    margin: "10px auto",
    height: "80px",
    width: "80px",
  },
  downloadButton: {
    marginTop: "15px",
  },
  buttonContainers: {
    marginTop: "10px",
    justifyContent: "space-around",
  },
  dataContainer: {
    padding: "25px",
    background: `${theme.palette.primary.main}`,
  },
  userData: {
    marginTop: 0,
    marginBottom: "10px",
    color: colors.orange["300"],
  },
  field: {
    marginBottom: "25px",
  },
});

const User = (props) => {
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("success");
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    db.collection("Users")
      .doc(id)
      .get()
      .then((response) => {
        let data = response.data();
        if (!data) {
          setMessage("User not found");
          setMessageType("error");
          setMessageOpen(true);
        } else {
          let newUser = new UserModel(data);
          newUser.uID = id;
          setUser(newUser);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setMessage(error.message);
        setMessageType("error");
        setMessageOpen(true);
      });
  }, [id]);

  const updateConfirmStatus = useCallback(
    (status) => {
      db.collection("Users")
        .doc(user.uID)
        .update({
          confirm_status: status,
        })
        .then((data) => {
          setMessage("User status updated successfully");
          setMessageType("success");
          setMessageOpen(true);

          setUser({ ...user, confirmStatus: status });
        })
        .catch(function (error) {
          setMessage(error.message);
          setMessageType("error");
          setMessageOpen(true);
        });
    },
    [user]
  );

  const sendEmail = useCallback(() => {
    const config = {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        Token: APP.apiKey,
      },
    };

    console.log(subject, emailMessage);

    if (subject && emailMessage) {
      setLoading(true);
      axios
        .post(
          APP.appURL + "/sendEmail",
          {
            email: user.email,
            message: emailMessage,
            subject: subject,
          },
          config
        )
        .then((response) => {
          setMessage("Email Sent successfully");
          setMessageType("success");
          setMessageOpen(true);

          setEmailDialogOpen(false);
        })
        .catch((error) => {
          setMessage(error.message);
          setMessageType("error");
          setMessageOpen(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [emailMessage, subject, user]);

  return (
    <Container maxWidth="xl" className={props.classes.container}>
      {user ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Paper className={props.classes.infoContainer}>
              <Grid container>
                <Grid item xs={4} sm={3} md={12}>
                  <Avatar
                    className={props.classes.avatar}
                    src={user.imageURL}
                  />
                </Grid>
                <Grid item xs={6} sm={5} md={12}>
                  <Typography variant="h6">
                    {user.firstName + " " + user.lastName}
                  </Typography>
                  <Typography variant="body1">{user.email}</Typography>
                  <Typography variant="body1">{user.tel}</Typography>
                </Grid>
                <Grid item xs={2} sm={4} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {user.resumeFileURL ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          endIcon={<FontAwesomeIcon icon={faArrowCircleDown} />}
                          href={user.resumeFileURL}
                          target="_blank"
                          className={props.classes.downloadButton}
                          fullWidth
                        >
                          <Hidden xsDown>Download Resume</Hidden>
                        </Button>
                      ) : null}
                    </Grid>

                    <Grid
                      className={props.classes.actionButton}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                    >
                      <Button fullWidth variant="outlined" size="small">
                        <Link to={"/chats/" + user.uID}>Chat</Link>
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Button
                        className={props.classes.actionButton}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onClick={() => setEmailDialogOpen(true)}
                      >
                        Send Email
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className={props.classes.buttonContainers}>
                  {user.confirmStatus ? (
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      size="small"
                      onClick={() => updateConfirmStatus(false)}
                    >
                      Reject
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      size="small"
                      onClick={() => updateConfirmStatus(true)}
                    >
                      Confirm
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>

            <Hidden smDown>
              <Paper className={props.classes.qrCodeContainer}>
                <QRCode size={220} value={user.uID} />
              </Paper>
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Paper className={props.classes.dataContainer}>
              <Typography variant="h6">Email:</Typography>
              <Typography className={props.classes.userData} variant="body1">
                {user.email}
              </Typography>

              <Typography variant="h6">Tel:</Typography>
              <Typography className={props.classes.userData} variant="body1">
                {user.tel}
              </Typography>

              <Typography variant="h6">Mobile:</Typography>
              <Typography className={props.classes.userData} variant="body1">
                {user.mobile}
              </Typography>

              <Typography variant="h6">Address:</Typography>
              <Typography className={props.classes.userData} variant="body1">
                {user.address}
              </Typography>

              <Typography variant="h6">Created:</Typography>
              <Typography className={props.classes.userData} variant="body1">
                {user.createdAt ? getDateTime(user.createdAt).toString() : ""}
              </Typography>

              <Typography variant="h6">Type Of Clearance:</Typography>
              <Typography className={props.classes.userData} variant="body1">
                {typeOfClearances[user.typeOfClearance] ?? ""}
              </Typography>

              <Typography variant="h6">Education Level:</Typography>
              <Typography className={props.classes.userData} variant="body1">
                {educationLevels[user.educationLevel] ?? ""}
              </Typography>

              <Typography variant="h6">Certifications:</Typography>
              <ul className={props.classes.userData}>
                {user.certifications?.map((certification, index) => (
                  <li key={`certification-item-${index}`}>
                    <Typography variant="body1">
                      {certification.certification}
                    </Typography>
                  </li>
                ))}
              </ul>

              <Typography variant="h6">Skills:</Typography>
              <ul className={props.classes.userData}>
                {user.skills?.map((skill, index) => (
                  <li key={`skill-item-${index}`}>
                    <Typography variant="body1">
                      Code: {skill.code} - Years of experience: {skill.years}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        </Grid>
      ) : null}

      <Dialog
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
        scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title">Send Email</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <form>
              <TextField
                variant="outlined"
                fullWidth
                label="Subject"
                className={props.classes.field}
                onChange={(e) => setSubject(e.target.value)}
              />
              <TextField
                variant="outlined"
                multiline
                fullWidth
                rows={8}
                label="Message"
                onChange={(e) => setEmailMessage(e.target.value)}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEmailDialogOpen(false)}>Cancel</Button>
          <Button onClick={sendEmail}>
            {loading ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={messageOpen}
        autoHideDuration={6000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert onClose={() => setMessageOpen(false)} severity={messageType}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withRouter(withStyles(styles)(User));
