import { useState } from "react";
import { db } from "../helpers/firebase";

const initialFormValues = {
  mobile: "",
  typeOfClearance: 0,
  educationLevel: 0,
  resumeFileURL: "",
  skills: [
    {
      code: "",
      years: "",
    },
  ],
  certifications: [
    {
      certification: "",
    },
  ],
  formSubmitted: false,
  success: false,
};

const required = {
  mobile: "",
  typeOfClearance: "",
  educationLevel: "",
};

export const useFormControls = (uid) => {
  const [values, setValues] = useState(initialFormValues);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("success");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    for (const [key, value] of Object.entries(fieldValues)) {
      if (key in required) temp[key] = value ? "" : "This field is required.";
    }

    if ("mobile" in fieldValues) {
      if (fieldValues.mobile)
        temp.mobile = /^\d*$/.test(fieldValues.mobile)
          ? ""
          : "Mobile is not valid.";
    }

    if ("typeOfClearance" in fieldValues) {
      if (fieldValues.typeOfClearance === 0) {
        temp.typeOfClearance = "This field is required.";
      }
    }

    console.log(temp);

    setErrors({
      ...temp,
    });
  };
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formIsValid()) {
      db.collection("Users")
        .doc(uid)
        .update({
          mobile: values.mobile,
          education_level: values.educationLevel,
          type_of_clearance: values.typeOfClearance,
          skills: values.skills,
          certifications: values.certifications,
        })
        .then((data) => {
          setMessage("Form submit was successful");
          setMessageType("success");
          setMessageOpen(true);
        })
        .catch(function (error) {
          setMessage(error.message);
          setMessageType("error");
          setMessageOpen(true);
        });
    }
  };
  const formIsValid = (fieldValues = values) => {
    return (
      fieldValues.mobile &&
      fieldValues.certifications &&
      fieldValues.typeOfClearance &&
      Object.values(errors).every((x) => x === "")
    );
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    values,
    setValues,
    errors,
    message,
    setMessage,
    messageOpen,
    setMessageOpen,
    messageType,
    setMessageType,
  };
};
