import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

let firebaseConfig = {
  apiKey: "AIzaSyCD8zeZDIMzJn8pYTYT4e3pDzDO2Fz5q1s",
  authDomain: "transition-app-79f29.firebaseapp.com",
  projectId: "transition-app-79f29",
  storageBucket: "transition-app-79f29.appspot.com",
  messagingSenderId: "465607756899",
  appId: "1:465607756899:web:4d9061b98cba485230bc56",
  measurementId: "G-VD9F0SD1K7",
};

if (window.location.hostname === "localhost") {
  firebaseConfig = {
    apiKey: "AIzaSyDnuzeAX39Z45aA7OeI_omvSHZJ7x1BmTw",
    authDomain: "transition-f1809.firebaseapp.com",
    projectId: "transition-f1809",
    storageBucket: "transition-f1809.appspot.com",
    messagingSenderId: "569113100840",
    appId: "1:569113100840:web:7ff08ba69167834c57eb1d",
    measurementId: "G-JR0HQ1CP7G",
  };
}

firebase.initializeApp(firebaseConfig);

export default firebase;
