const AppMenu = [
  {
    label: "Users",
    pathname: "/users",
  },
  {
    label: "Chats",
    pathname: "/chats",
  },
  {
    label: "Bulk PDF",
    pathname: "/bulk-pdf",
  },
  {
    label: "Bulk Email",
    pathname: "/bulk-email",
  },
  {
    label: "logout",
    pathname: "/logout",
  },
];

export default AppMenu;
