export const typeOfMOS = [
  {
    MOS: "09L",
    Title: "INTERPRETER/TRANSLATOR",
  },
  {
    MOS: "11B",
    Title: "INFANTRYMAN",
  },
  {
    MOS: "11C",
    Title: "INDIRECT FIRE INFANTRYMAN",
  },
  {
    MOS: "11Z",
    Title: "INFANTRY SENIOR SERGEANT",
  },
  {
    MOS: "12A",
    Title: "ENGINEER SENIOR SERGEANT",
  },
  {
    MOS: "12B",
    Title: "COMBAT ENGINEER",
  },
  {
    MOS: "12C",
    Title: "BRIDGE CREWMEMBER",
  },
  {
    MOS: "12D",
    Title: "DIVER",
  },
  {
    MOS: "12G",
    Title: "QUARRYING SPECIALIST (RC)",
  },
  {
    MOS: "12H",
    Title: "CONSTRUCTION ENGINEERING SUPERVISOR",
  },
  {
    MOS: "12K",
    Title: "PLUMBER",
  },
  {
    MOS: "12M",
    Title: "FIREFIGHTER",
  },
  {
    MOS: "12N",
    Title: "HORIZONTAL CONSTRUCTION ENGINEER",
  },
  {
    MOS: "12P",
    Title: "PRIME POWER PRODUCTION SPECIALIST",
  },
  {
    MOS: "12Q",
    Title: "POWER LINE DISTRIBUTION SPECIALIST (RC)",
  },
  {
    MOS: "12R",
    Title: "INTERIOR ELECTRICIAN",
  },
  {
    MOS: "12T",
    Title: "TECHNICAL ENGINEER",
  },
  {
    MOS: "12V",
    Title: "CONCRETE AND ASPHALT EQUIPMENT OPERATOR",
  },
  {
    MOS: "12W",
    Title: "CARPENTRY AND MASONRY SPECIALIST",
  },
  {
    MOS: "12X",
    Title: "GENERAL ENGINEERING SUPERVISOR",
  },
  {
    MOS: "12Y",
    Title: "GEOSPATIAL ENGINEER",
  },
  {
    MOS: "12Z",
    Title: "COMBAT ENGINEERING SENIOR SERGEANT",
  },
  {
    MOS: "13B",
    Title: "CANNON CREWMEMBER",
  },
  {
    MOS: "13F",
    Title: "FIRE SUPPORT SPECIALIST",
  },
  {
    MOS: "13M",
    Title:
      "MULTIPLE LAUNCH ROCKET SYSTEM (MLRS)/HIGH MOBILITY ARTILLERY ROCKET SYSTEM (HIMARS) CREWMEMBER",
  },
  {
    MOS: "13J",
    Title: "FIRE CONTROL SPECIALIST",
  },
  {
    MOS: "13R",
    Title: "FIELD ARTILLERY FIREFINDER RADAR OPERATOR",
  },
  {
    MOS: "13T",
    Title: "FIELD ARTILLERY SURVEYOR/METEOROLOGICAL CREWMEMBER",
  },
  {
    MOS: "13Z",
    Title: "FIELD ARTILLERY SENIOR SERGEANT",
  },
  {
    MOS: "14E",
    Title: "PATRIOT FIRE CONTROL ENHANCED OPERATOR/MAINTAINER",
  },
  {
    MOS: "14G",
    Title: "AIR DEFENSE BATTLE MANAGEMENT SYSTEM OPERATOR",
  },
  {
    MOS: "14H",
    Title: "AIR DEFENSE ENHANCED EARLY WARNING OPERATOR",
  },
  {
    MOS: "14P",
    Title: "AIR AND MISSILE DEFENSE(AMD) CREWMEMBER",
  },
  {
    MOS: "14S",
    Title: "AIR AND MISSILE DEFENSE (AMD) CREWMEMBER",
  },
  {
    MOS: "14T",
    Title: "PATRIOT LAUNCHING STATION ENHANCED OPERATOR/MAINTAINER",
  },
  {
    MOS: "14Z",
    Title: "AIR DEFENSE ARTILLERY SENIOR SERGEANT",
  },
  {
    MOS: "15B",
    Title: "AIRCRAFT POWERPLANT REPAIRER",
  },
  {
    MOS: "15C",
    Title: "MQ-1 OPERATOR (Eff 202010)",
  },
  {
    MOS: "15D",
    Title: "AIRCRAFT POWERTRAIN REPAIRER",
  },
  {
    MOS: "15E",
    Title: "UNMANNED AIRCRAFT SYSTEMS REPAIRER",
  },
  {
    MOS: "15E",
    Title: "RQ-7 REPAIRER (Eff 202010)",
  },
  {
    MOS: "15F",
    Title: "AIRCRAFT ELECTRICIAN",
  },
  {
    MOS: "15G",
    Title: "AIRCRAFT STRUCTURAL REPAIRER",
  },
  {
    MOS: "15H",
    Title: "AIRCRAFT PNEUDRAULICS REPAIRER",
  },
  {
    MOS: "15J",
    Title: "OH-58D ARMAMENT/ELECTRICAL/AVIONICS SYSTEMS REPAIRER",
  },
  {
    MOS: "15K",
    Title: "AIRCRAFT COMPONENTS REPAIR SUPERVISOR",
  },
  {
    MOS: "15L",
    Title: "ARMAMENT/ELECTRICAL/AVIONICS REPAIR SUPERVISOR (Eff 201910)",
  },
  {
    MOS: "15M",
    Title: "MQ-1 REPAIRER (Eff 202010)",
  },
  {
    MOS: "15N",
    Title: "AVIONIC MECHANIC",
  },
  {
    MOS: "15P",
    Title: "AVIATION OPERATIONS SPECIALIST",
  },
  {
    MOS: "15Q",
    Title: "AIR TRAFFIC CONTROL OPERATOR",
  },
  {
    MOS: "15R",
    Title: "AH-64 ATTACK HELICOPTER REPAIRER",
  },
  {
    MOS: "15S",
    Title: "OH-58D HELICOPTER REPAIRER",
  },
  {
    MOS: "15T",
    Title: "UH-60 HELICOPTER REPAIRER",
  },
  {
    MOS: "15U",
    Title: "CH-47 HELICOPTER REPAIRER",
  },
  {
    MOS: "15W",
    Title: "UNMANNED AIRCRAFT SYSTEMS OPERATOR",
  },
  {
    MOS: "15W",
    Title: "RQ-7 OPERATOR (Eff 202010)",
  },
  {
    MOS: "15Y",
    Title: "AH-64D ARMAMENT/ELECTRICAL/AVIONIC SYSTEMS REPAIRER",
  },
  {
    MOS: "15Z",
    Title: "AIRCRAFT MAINTENANCE SENIOR SERGEANT",
  },
  {
    MOS: "17C",
    Title: "CYBER OPERATIONS SPECIALIST",
  },
  {
    MOS: "17E",
    Title: "ELECTRONIC WARFARE SPECIALIST",
  },
  {
    MOS: "18B",
    Title: "SPECIAL FORCES WEAPONS SERGEANT",
  },
  {
    MOS: "18C",
    Title: "SPECIAL FORCES ENGINEER SERGEANT",
  },
  {
    MOS: "18D",
    Title: "SPECIAL FORCES MEDICAL SERGEANT",
  },
  {
    MOS: "18E",
    Title: "SPECIAL FORCES COMMUNICATIONS SERGEANT",
  },
  {
    MOS: "18F",
    Title: "SPECIAL FORCES ASSISTANT OPERATIONS AND INTELLIGENCE SERGEANT",
  },
  {
    MOS: "18Z",
    Title: "SPECIAL FORCES SENIOR SERGEANT",
  },
  {
    MOS: "19D",
    Title: "CAVALRY SCOUT",
  },
  {
    MOS: "19K",
    Title: "M1 ARMOR CREWMAN",
  },
  {
    MOS: "19Z",
    Title: "ARMOR SENIOR SERGEANT",
  },
  {
    MOS: "25B",
    Title: "INFORMATION TECHNOLOGY SPECIALIST",
  },
  {
    MOS: "25C",
    Title: "RADIO OPERATOR-MAINTAINER",
  },
  {
    MOS: "25D",
    Title: "CYBER NETWORK DEFENDER",
  },
  {
    MOS: "25E",
    Title: "ELECTROMAGNETIC SPECTRUM MANAGER",
  },
  {
    MOS: "25L",
    Title: "CABLE SYSTEMS INSTALLER-MAINTAINER",
  },
  {
    MOS: "25M",
    Title: "MULTIMEDIA ILLUSTRATOR",
  },
  {
    MOS: "25N",
    Title: "NODAL NETWORK SYSTEMS OPERATOR-MAINTAINER",
  },
  {
    MOS: "25P",
    Title: "MICROWAVE SYSTEMS OPERATOR-MAINTAINER",
  },
  {
    MOS: "25Q",
    Title: "MULTICHANNEL TRANSMISSION SYSTEMS OPERATOR-MAINTAINER",
  },
  {
    MOS: "25R",
    Title: "VISUAL INFORMATION EQUIPMENT OPERATOR-MAINTAINER",
  },
  {
    MOS: "25S",
    Title: "SATELLITE COMMUNICATION SYSTEMS OPERATOR-MAINTAINER",
  },
  {
    MOS: "25T",
    Title: "SATELLITE/MICROWAVE SYSTEMS CHIEF",
  },
  {
    MOS: "25U",
    Title: "SIGNAL SUPPORT SYSTEMS SPECIALIST",
  },
  {
    MOS: "25V",
    Title: "COMBAT DOCUMENTATION/PRODUCTION SPECIALIST",
  },
  {
    MOS: "25W",
    Title: "TELECOMMUNICATIONS OPERATIONS CHIEF",
  },
  {
    MOS: "25X",
    Title: "CHIEF SIGNAL NCO",
  },
  {
    MOS: "25Z",
    Title: "VISUAL INFORMATION OPERATIONS CHIEF",
  },
  {
    MOS: "27D",
    Title: "PARALEGAL SPECIALIST",
  },
  {
    MOS: "31B",
    Title: "MILITARY POLICE",
  },
  {
    MOS: "31D",
    Title: "CID SPECIAL AGENT",
  },
  {
    MOS: "31E",
    Title: "INTERNMENT/RESETTLEMENT SPECIALIST",
  },
  {
    MOS: "31K",
    Title: "MILITARY WORKING DOG (MWD) HANDLER",
  },
  {
    MOS: "31Z",
    Title: "SENIOR MILITARY POLICE SERGEANT",
  },
  {
    MOS: "35F",
    Title: "INTELLIGENCE ANALYST",
  },
  {
    MOS: "35G",
    Title: "GEOSPATIAL INTELLIGENCE IMAGERY ANALYST",
  },
  {
    MOS: "35L",
    Title: "COUNTER INTELLIGENCE AGENT",
  },
  {
    MOS: "5M-",
    Title: "HUMAN INTELLIGENCE COLLECTOR",
  },
  {
    MOS: "35N",
    Title: "SIGNALS INTELLIGENCE ANALYST",
  },
  {
    MOS: "35P",
    Title: "CRYPTOLOGIC LINGUIST",
  },
  {
    MOS: "35Q",
    Title: "CRYPTOLOGIC NETWORK WARFARE SPECIALIST (Res 202010)",
  },
  {
    MOS: "35S",
    Title: "SIGNALS COLLECTOR/ANALYST",
  },
  {
    MOS: "35T",
    Title: "MILITARY INTELLIGENCE SYSTEMS MAINTAINER/INTEGRATOR",
  },
  {
    MOS: "35V",
    Title:
      "SIGNALS INTELLIGENCE (SIGINT) SENIOR SERGEANT/SIGINT CHIEF (Res 202010)",
  },
  {
    MOS: "35X",
    Title:
      "INTELLIGENCE SENIOR SERGEANT/CHIEF INTELLIGENCE SERGEANT (Res 202010)",
  },
  {
    MOS: "35Y",
    Title:
      "CHIEF COUNTER INTELLIGENCE/HUMAN INTELLIGENCE SERGEANT (Res 202010)",
  },
  {
    MOS: "35Z",
    Title: "INTELLIGENCE SERGEANT MAJOR",
  },
  {
    MOS: "35Z",
    Title: "INTELLIGENCE SENIOR SERGEANT (Eff 202010)",
  },
  {
    MOS: "36B",
    Title: "FINANCIAL MANAGEMENT TECHNICIAN",
  },
  {
    MOS: "37F",
    Title: "PSYCHOLOGICAL OPERATIONS SPECIALIST",
  },
  {
    MOS: "38B",
    Title: "CIVIL AFFAIRS SPECIALIST",
  },
  {
    MOS: "42A",
    Title: "HUMAN RESOURCES SPECIALIST",
  },
  {
    MOS: "42R",
    Title: "MUSICIAN",
  },
  {
    MOS: "42S",
    Title: "SPECIAL BAND MUSICIAN",
  },
  {
    MOS: "46S",
    Title: "PUBLIC AFFAIRS MASS COMMUNICATION SPECIALIST",
  },
  {
    MOS: "46Z",
    Title: "CHIEF PUBLIC AFFAIRS NCO",
  },
  {
    MOS: "51C",
    Title: "ACQUISITION, LOGISTICS & TECHNOLOGY (AL&T) CONTRACTING NCO",
  },
  {
    MOS: "56M",
    Title: "RELIGIOUS AFFAIRS SPECIALIST",
  },
  {
    MOS: "68A",
    Title: "BIOMEDICAL EQUIPMENT SPECIALIST",
  },
  {
    MOS: "68B",
    Title: "ORTHOPEDIC SPECIALIST",
  },
  {
    MOS: "68C",
    Title: "PRACTICAL NURSING SPECIALIST",
  },
  {
    MOS: "68D",
    Title: "OPERATING ROOM SPECIALIST",
  },
  {
    MOS: "68E",
    Title: "DENTAL SPECIALIST",
  },
  {
    MOS: "68F",
    Title: "PHYSICAL THERAPY SPECIALIST",
  },
  {
    MOS: "68G",
    Title: "PATIENT ADMINISTRATION SPECIALIST",
  },
  {
    MOS: "68H",
    Title: "OPTICAL LABORATORY SPECIALIST",
  },
  {
    MOS: "68J",
    Title: "MEDICAL LOGISTICS SPECIALIST",
  },
  {
    MOS: "68K",
    Title: "MEDICAL LABORATORY SPECIALIST",
  },
  {
    MOS: "68L",
    Title: "OCCUPATIONAL THERAPY SPECIALIST",
  },
  {
    MOS: "68M",
    Title: "NUTRITION CARE SPECIALIST",
  },
  {
    MOS: "68N",
    Title: "CARDIOVASCULAR SPECIALIST",
  },
  {
    MOS: "68P",
    Title: "RADIOLOGY SPECIALIST",
  },
  {
    MOS: "68Q",
    Title: "PHARMACY SPECIALIST",
  },
  {
    MOS: "68R",
    Title: "VETERINARY FOOD INSPECTION SPECIALIST",
  },
  {
    MOS: "68S",
    Title: "PREVENTIVE MEDICINE SPECIALIST",
  },
  {
    MOS: "68T",
    Title: "ANIMAL CARE SPECIALIST",
  },
  {
    MOS: "68U",
    Title: "EAR, NOSE AND THROAT (ENT) SPECIALIST",
  },
  {
    MOS: "68V",
    Title: "RESPIRATORY SPECIALIST",
  },
  {
    MOS: "68W",
    Title: "COMBAT MEDIC SPECIALIST",
  },
  {
    MOS: "68X",
    Title: "BEHAVIORAL HEALTH SPECIALIST",
  },
  {
    MOS: "68Y",
    Title: "EYE SPECIALIST",
  },
  {
    MOS: "68Z",
    Title: "CHIEF MEDICAL NCO",
  },
  {
    MOS: "74D",
    Title: "CHEMICAL, BIOLOGICAL, RADIOLOGICAL AND NUCLEAR (CBRN) SPECIALIST",
  },
  {
    MOS: "79R",
    Title: "RECRUITER",
  },
  {
    MOS: "79S",
    Title: "CAREER COUNSELOR",
  },
  {
    MOS: "79T",
    Title: "RECRUITING AND RETENTION NCO (ARNG)",
  },
  {
    MOS: "79V",
    Title: "RETENTION AND TRANSITION NCO (USAR)",
  },
  {
    MOS: "88H",
    Title: "CARGO SPECIALIST",
  },
  {
    MOS: "88K",
    Title: "WATERCRAFT OPERATOR",
  },
  {
    MOS: "88L",
    Title: "WATERCRAFT ENGINEER",
  },
  {
    MOS: "88M",
    Title: "MOTOR TRANSPORT OPERATOR",
  },
  {
    MOS: "88N",
    Title: "TRANSPORTATION MANAGEMENT COORDINATOR",
  },
  {
    MOS: "88U",
    Title: "RAILWAY OPERATIONS CREWMEMBER (RC)",
  },
  {
    MOS: "88Z",
    Title: "TRANSPORTATION SENIOR SERGEANT",
  },
  {
    MOS: "89A",
    Title: "AMMUNITION STOCK CONTROL AND ACCOUNTING SPECIALIST",
  },
  {
    MOS: "89B",
    Title: "AMMUNITION SPECIALIST",
  },
  {
    MOS: "89D",
    Title: "EXPLOSIVE ORDNANCE DISPOSAL SPECIALIST",
  },
  {
    MOS: "91A",
    Title: "M1 ABRAMS TANK SYSTEM MAINTAINER",
  },
  {
    MOS: "91B",
    Title: "WHEELED VEHICLE MECHANIC",
  },
  {
    MOS: "91C",
    Title: "UTILITIES EQUIPMENT REPAIRER",
  },
  {
    MOS: "91D",
    Title: "POWER-GENERATION EQUIPMENT REPAIRER",
  },
  {
    MOS: "91E",
    Title: "ALLIED TRADES SPECIALIST",
  },
  {
    MOS: "91F",
    Title: "SMALL ARMS/ARTILLERY REPAIRER",
  },
  {
    MOS: "91H",
    Title: "TRACK VEHICLE REPAIRER",
  },
  {
    MOS: "91J",
    Title: "QUARTERMASTER AND CHEMICAL EQUIPMENT REPAIRER",
  },
  {
    MOS: "91L",
    Title: "CONSTRUCTION EQUIPMENT REPAIRER",
  },
  {
    MOS: "91M",
    Title: "BRADLEY FIGHTING VEHICLE SYSTEM MAINTAINER",
  },
  {
    MOS: "91P",
    Title: "ARTILLERY MECHANIC",
  },
  {
    MOS: "91S",
    Title: "STRYKER SYSTEMS MAINTAINER",
  },
  {
    MOS: "91X",
    Title: "MAINTENANCE SUPERVISOR",
  },
  {
    MOS: "91Z",
    Title: "MECHANICAL MAINTENANCE SUPERVISOR",
  },
  {
    MOS: "92A",
    Title: "AUTOMATED LOGISTICAL SPECIALIST",
  },
  {
    MOS: "92F",
    Title: "PETROLEUM SUPPLY SPECIALIST",
  },
  {
    MOS: "92G",
    Title: "CULINARY SPECIALIST",
  },
  {
    MOS: "92L",
    Title: "PETROLEUM LABORATORY SPECIALIST",
  },
  {
    MOS: "92M",
    Title: "MORTUARY AFFAIRS SPECIALIST",
  },
  {
    MOS: "92R",
    Title: "PARACHUTE RIGGER",
  },
  {
    MOS: "92S",
    Title: "SHOWER AND LAUNDRY SPECIALIST",
  },
  {
    MOS: "92W",
    Title: "WATER TREATMENT SPECIALIST",
  },
  {
    MOS: "92Y",
    Title: "UNIT SUPPLY SPECIALIST",
  },
  {
    MOS: "92Z",
    Title: "SENIOR NONCOMMISSIONED LOGISTICIAN",
  },
  {
    MOS: "94A",
    Title: "LAND COMBAT ELECTRONIC MISSILE SYSTEM REPAIRER",
  },
  {
    MOS: "94D",
    Title: "AIR TRAFFIC CONTROL EQUIPMENT REPAIRER",
  },
  {
    MOS: "94E",
    Title: "RADIO EQUIPMENT REPAIRER",
  },
  {
    MOS: "94F",
    Title: "COMPUTER DETECTION SYSTEMS REPAIRER",
  },
  {
    MOS: "94H",
    Title: "(TMDE) MAINTENANCE SUPPORT SPECIALIST",
  },
  {
    MOS: "94M",
    Title: "RADAR REPAIRER",
  },
  {
    MOS: "94P",
    Title: "MULTIPLE LAUNCH ROCKET SYSTEM REPAIRER",
  },
  {
    MOS: "94R",
    Title: "AVIONIC AND SURVIVABILITY EQUIPMENT REPAIRER",
  },
  {
    MOS: "94S",
    Title: "PATRIOT SYSTEM REPAIRER",
  },
  {
    MOS: "94T",
    Title: "AVENGER SYSTEM REPAIRER",
  },
  {
    MOS: "94W",
    Title: "ELECTRONIC MAINTENANCE CHIEF",
  },
  {
    MOS: "94X",
    Title: "SENIOR MISSILE SYSTEMS MAINTAINER",
  },
  {
    MOS: "94Z",
    Title: "SENIOR ELECTRONIC MAINTENANCE CHIEF",
  },
];
