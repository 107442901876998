import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Avatar,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { db } from "../helpers/firebase";
import { UserModel } from "../models";
import IconButton from "@material-ui/core/IconButton";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = (theme) => ({
  heading: {
    textAlign: "left",
    margin: "30px 0 20px",
    padding: "0 15px",
  },
  title: {
    marginTop: "10px",
  },
  searchField: {
    marginTop: "10px",
  },
  tableContainer: {
    background: `${theme.palette.primary.main}`,
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0,0,0,.4)",
  },
  actionButton: {
    padding: "10px",
  },
});

const columns = [
  { id: "image", label: "" },
  { id: "firstName", label: "First Name", minWidth: 100 },
  { id: "lastName", label: "Last Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "tel", label: "Tel", minWidth: 70 },
  { id: "actions", label: "Actions", minWidth: 100 },
];

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [query, setQuery] = useState("");

  useEffect(() => {
    db.collection("Users")
      .limit(10000)
      .get()
      .then((response) => {
        let data = response.docs;
        const users = data
          .filter((doc) => {
            return doc.data().role_id === 20;
          })
          .map((doc) => {
            let user = new UserModel(doc.data());
            user.uID = doc.id;
            return user;
          });
        setUsers(users);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      return (
        user.firstName?.toLowerCase().includes(query) ||
        user.firstName?.toLowerCase().includes(query) ||
        user.email?.toLowerCase().includes(query) ||
        user.tel?.toLowerCase().includes(query) ||
        user.mobile?.toLowerCase().includes(query) ||
        user.certifications?.some((certification) =>
          certification.certification.toLowerCase().includes(query)
        ) ||
        user.skills?.some((skill) => skill.code.includes(query))
      );
    });
  }, [query, users]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container>
      <Grid container alignItems="center" className={props.classes.heading}>
        <Grid item xs={12} sm={12} md={2}>
          <Typography className={props.classes.title} variant="h5">
            Users list
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={10}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            className={props.classes.searchField}
            placeholder="Search by firstName, lastName, mobile, tell, MOS, certification"
            onChange={(event) => setQuery(event.target.value)}
          />
        </Grid>
      </Grid>
      <TableContainer
        className={props.classes.tableContainer}
        sx={{ maxHeight: 440 }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={user.uID}>
                    <TableCell>
                      <Avatar src={user.imageURL} />
                    </TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.tel}</TableCell>
                    <TableCell>
                      <IconButton
                        color="default"
                        aria-label="View"
                        component={Link}
                        to={"/user/" + user.uID}
                        size="small"
                        className={props.classes.actionButton}
                      >
                        <FontAwesomeIcon icon={faEye} size="xs" />
                      </IconButton>
                      {/*<IconButton
                          color="white"
                          aria-label="Delete"
                          component="span"
                          size="small"
                          className={props.classes.actionButton}
                        >
                          <FontAwesomeIcon icon={faTrash} size="xs" />
                        </IconButton>*/}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default withRouter(withStyles(styles)(Users));
