import React, { useCallback, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useFormControls } from "../validations/contact";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import BottomMenu from "../components/ButtomMenu";

const styles = (theme) => ({
  container: {
    minHeight: "calc(100vh - 65px)",
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    textAlign: "center",
    margin: "40px 0",
  },
  form: {
    width: "100%",
    paddingBottom: "20px",
  },
  field: {
    marginBottom: "25px",
  },
  mt10: {
    marginTop: "10px",
    display: "block",
  },
  bottomMenu: {
    background: `${theme.palette.primary.dark}`,
    marginTop: "auto",
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ContactUs = (props) => {
  const form = useRef();
  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    responseError,
    errorOpen,
    setErrorOpen,
    messageSent,
    setMessageSent,
    loading,
  } = useFormControls();

  useEffect(() => {
    if (messageSent) {
      form.current.reset();
      setMessageSent(false);
    }
  }, [messageSent, setMessageSent]);

  return (
    <div className={props.classes.container}>
      <Container maxWidth="md">
        <Grid container direction="row" justifyContent="center">
          <Grid item sm={12} md={7}>
            <Typography variant="h3" className={props.classes.heading}>
              Contact us
            </Typography>
          </Grid>
          <Grid item sm={12} md={7}>
            <form
              className={props.classes.form}
              ref={form}
              onSubmit={handleFormSubmit}
              noValidate
              autoComplete="off"
            >
              <TextField
                name="name"
                className={props.classes.field}
                label="Name and Family"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                fullWidth
                {...(errors["name"] && {
                  error: true,
                  helperText: errors["name"],
                })}
              />
              <TextField
                name="email"
                fullWidth
                label="Email"
                className={props.classes.field}
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["email"] && {
                  error: true,
                  helperText: errors["email"],
                })}
              />
              <TextField
                name="phone"
                fullWidth
                label="Phone Number"
                className={props.classes.field}
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["phone"] && {
                  error: true,
                  helperText: errors["phone"],
                })}
              />
              <TextField
                name="message"
                className={props.classes.field}
                label="Message"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                fullWidth
                multiline
                rows={8}
                {...(errors["message"] && {
                  error: true,
                  helperText: errors["message"],
                })}
              />

              <Button
                fullWidth
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                className={props.classes.margin}
                disabled={!formIsValid() || loading}
              >
                {loading ? (
                  <CircularProgress size="2rem" color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>

      <Grid
        className={props.classes.bottomMenu}
        container
        justifyContent="center"
        alignContent="center"
        spacing={3}
      >
        {BottomMenu.map((menuItem, index) => (
          <Button
            key={`bottom-menu-item-${index}`}
            className={props.classes.links}
            color="default"
            size="large"
          >
            <Link to={menuItem.pathname}>{menuItem.label}</Link>
          </Button>
        ))}
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          {responseError}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withRouter(withStyles(styles)(ContactUs));
