import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Avatar,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { db } from "../helpers/firebase";
import { UserModel } from "../models";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { APP } from "../consts/app";
import MuiAlert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  emailPanel: {
    height: "calc(100vh - 65px)",
    alignItems: "flex-start",
    display: "flex",
  },
  leftPanel: {
    backgroundColor: `${theme.palette.primary.dark}`,
    height: "100%",
    display: "flex",
    overflowY: "auto",
    "& ul.MuiList-root": {
      width: "100%",
    },
  },
  stats: {
    padding: "15px",
  },
  form: {
    padding: "0 15px",
  },
  field: {
    marginBottom: "25px",
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Email = (props) => {
  const [users, setUsers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [sentEmails, setSentEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [responseError, setResponseError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);

  useEffect(() => {
    db.collection("Users")
      .limit(10000)
      .get()
      .then((response) => {
        let data = response.docs;
        const users = data
          .filter((doc) => {
            return doc.data().role_id === 20;
          })
          .map((doc) => {
            let newUser = new UserModel(doc.data());
            newUser.uID = doc.id;
            return newUser;
          });
        setUsers(users);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleToggleUser = useCallback(
    (email) => {
      const index = selectedEmails.findIndex(
        (selectedEmail) => selectedEmail === email
      );

      if (index >= 0) {
        setSelectedEmails([
          ...selectedEmails.slice(0, index),
          ...selectedEmails.slice(index + 1),
        ]);
      } else {
        setSelectedEmails((selectedEmails) => [...selectedEmails, email]);
      }
    },
    [selectedEmails]
  );

  const sendEmails = useCallback(() => {
    const config = {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        Token: APP.apiKey,
      },
    };

    if (subject && emailMessage && selectedEmails.length) {
      setSentEmails([]);
      setLoading(true);
      selectedEmails.forEach(async (email) => {
        axios
          .post(
            APP.appURL + "/sendEmail",
            {
              email: email,
              message: emailMessage,
              subject: subject,
            },
            config
          )
          .catch((error) => {
            setResponseError(error.message);
            setErrorOpen(true);
          })
          .finally(() => {
            setSentEmails((sentEmails) => [...sentEmails, email]);
          });
      });
    }
  }, [emailMessage, selectedEmails, subject]);

  useEffect(() => {
    if (sentEmails.length === selectedEmails.length) {
      setLoading(false);
    }
  }, [selectedEmails.length, sentEmails.length]);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={3}
        alignItems="center"
        className={props.classes.emailPanel}
      >
        <Grid item xs={4} className={props.classes.leftPanel}>
          <List>
            {users.map((user, index) => {
              const labelId = `user-item-label-${index}`;
              return (
                <ListItem
                  key={user.uID}
                  onClick={() => handleToggleUser(user.email)}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      //onChange={() => handleToggleUser(user.email)}
                      checked={selectedEmails.includes(user.email)}
                      inputProps={{ "aria-labelledby": labelId }}
                      color="default"
                    />
                  }
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar
                        alt={user.firstName + " " + user.lastName}
                        src={user.imageURL}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={user.firstName + " " + user.lastName}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={8}>
          <Typography className={props.classes.stats}>
            {`Selected users: ${selectedEmails.length} - Sent emails:
            ${sentEmails.length}`}
          </Typography>

          <form className={props.classes.form}>
            <TextField
              variant="outlined"
              fullWidth
              label="Subject"
              className={props.classes.field}
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextField
              variant="outlined"
              multiline
              fullWidth
              rows={8}
              label="Message"
              className={props.classes.field}
              onChange={(e) => setEmailMessage(e.target.value)}
            />

            <Button
              fullWidth
              type="button"
              size="large"
              variant="contained"
              color="primary"
              className={props.classes.margin}
              disabled={
                loading || !subject || !emailMessage || !selectedEmails.length
              }
              onClick={() => sendEmails()}
            >
              {loading ? (
                <CircularProgress size="2rem" color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          {responseError}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withRouter(withStyles(styles)(Email));
