import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { auth } from "../helpers/firebase";

const Logout = (props) => {
  useEffect(() => {
    localStorage.removeItem("reduxState");
    auth.signOut().then(() => {
      props.history.push("/login");
    });
  }, [props.history]);

  return <></>;
};

export default withRouter(Logout);
