export function getJsTimestamp(mDate) {
  let date = mDate.toString().replace(",", "").replace(" at ", " ");
  return Date.parse(date);
}

export function getJsDate(mDate) {
  return new Date(getJsTimestamp(mDate));
}

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  let mainTime = [hours, minutes, seconds];
  let ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  if (mainTime[0] < 10) {
    mainTime[0] = "0" + mainTime[0].toString();
  }
  return date
    .toString()
    .replace(
      mainTime.join(":"),
      hours + ":" + minutes + ":" + seconds + " " + ampm
    );
}

export function getDartTimestamp(mDate) {
  let date = getJsDate(mDate);
  let year = date.getFullYear();
  let day = date.getDate();

  return formatAMPM(date)
    .toString()
    .slice(4, 100)
    .replace(year, year + " at")
    .replace(day, day + ",")
    .replace(/ *\([^)]*\) */g, "");
}

export function getDateTime(secs) {
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}
