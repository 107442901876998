export class UserModel {
  constructor(userData) {
    this.uID = userData.uid;
    this.firstName = userData.first_name;
    this.lastName = userData.last_name;
    this.email = userData.email;
    this.tel = userData.tel;
    this.mobile = userData.mobile;
    this.address = userData.address;
    this.typeOfClearance = userData.type_of_clearance;
    this.platform = userData.platform;
    this.createdAt =
      typeof userData.created_at === "object"
        ? userData.created_at.seconds
        : userData.created_at;
    this.imageURL = userData.image_url ?? "";
    this.roleID = userData.role_id;
    this.tags = userData.tags;
    this.skills = userData.skills;
    this.certifications = userData.certifications;
    this.token = userData.token;
    this.educationLevel = userData.education_level;
    this.status = userData.status;
    this.resumeFileURL = userData.resume_file_url ?? null;
    this.confirmStatus = userData.confirm_status ?? null;
    this.resumeStatus = userData.resume_status ?? null;
    this.newMsgStatus = userData.new_msg_status ?? null;
    this.chatRooms = userData.chat_rooms ?? null;
  }
}
