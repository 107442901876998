import React from "react";
import { Link, withRouter } from "react-router-dom";
import BottomMenu from "../components/ButtomMenu";
import Button from "@material-ui/core/Button";
import { Grid, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  container: {
    minHeight: "calc(100vh - 65px)",
    display: "flex",
    flexDirection: "column",
  },
  bottomMenu: {
    background: `${theme.palette.primary.dark}`,
    marginTop: "auto",
  },
});

const WhoWeAre = (props) => {
  return (
    <div className={props.classes.container}>
      <h1>WhoWeAre</h1>

      <Grid
        className={props.classes.bottomMenu}
        container
        justifyContent="center"
        alignContent="center"
        spacing={3}
      >
        {BottomMenu.map((menuItem, index) => (
          <Button
            key={`bottom-menu-item-${index}`}
            className={props.classes.links}
            color="default"
            size="large"
          >
            <Link to={menuItem.pathname}>{menuItem.label}</Link>
          </Button>
        ))}
      </Grid>
    </div>
  );
};

export default withRouter(withStyles(styles)(WhoWeAre));
