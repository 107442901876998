import React, { useMemo } from "react";
import { Route, HashRouter, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import WhoWeAre from "../pages/WhoWeAre";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Form from "../pages/Form";
import FileUpload from "../pages/FileUpload";
import Logout from "../pages/Logout";
import ScrollToTop from "../components/ScrollTop";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebase";
import AppRoute from "../components/AppRoute";
import { useSelector } from "react-redux";
import { userRoles } from "../consts/userRoles";
import Users from "../pages/Users";
import User from "../pages/User";
import Chat from "../pages/Chat";
import Chats from "../pages/Chats";
import BulkPdf from "../pages/BulkPdf";
import ContactUs from "../pages/ContactUs";
import WhatWeDo from "../pages/WhatWeDo";
import Email from "../pages/Email";

const Routes = () => {
  const [user, error] = useAuthState(auth);
  const roleId = useSelector((state) => state.user?.roleId);

  const authenticated = useMemo(() => {
    return user && !error;
  }, [error, user]);

  const isAdmin = useMemo(() => {
    return authenticated && roleId === userRoles.ADMIN;
  }, [authenticated, roleId]);

  const isUser = useMemo(() => {
    return authenticated && roleId === userRoles.USER;
  }, [authenticated, roleId]);

  return (
    <HashRouter>
      <ScrollToTop>
        <Switch>
          <AppRoute authed={isUser} exact path="/profile" component={Profile} />
          <AppRoute authed={isUser} exact path="/form" component={Form} />
          <AppRoute authed={isUser} exact path="/file" component={FileUpload} />
          <AppRoute authed={isUser} exact path="/chat" component={Chat} />

          <AppRoute authed={isAdmin} exact path="/users" component={Users} />
          <AppRoute authed={isAdmin} exact path="/user/:id" component={User} />
          <AppRoute
            authed={isAdmin}
            exact
            path="/chats/:id"
            component={Chats}
          />
          <AppRoute authed={isAdmin} exact path="/chats" component={Chats} />
          <AppRoute
            authed={isAdmin}
            exact
            path="/bulk-pdf"
            component={BulkPdf}
          />
          <AppRoute
            authed={isAdmin}
            exact
            path="/bulk-email"
            component={Email}
          />

          <Route exact path="/who-we-are" component={WhoWeAre} />
          <Route exact path="/what-we-do" component={WhatWeDo} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/" component={Home} />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
};

export default Routes;
