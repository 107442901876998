import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Box, Link as MaterialLink, withStyles } from "@material-ui/core";
import Menu from "./Menu";
import AppMenu from "./AppMenu";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Container from "@material-ui/core/Container";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebase";
import AdminMenu from "./AdminMenu";
import { userRoles } from "../consts/userRoles";
import { useSelector } from "react-redux";

const logo = require("../logo.svg");

const styles = (theme) => ({
  appBar: {
    //backgroundColor: `${theme.palette.primary.dark}`
  },
  inline: {
    display: "inline",
  },
  flex: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  productLogo: {
    display: "inline-block",
    borderLeft: `1px solid ${theme.palette.grey["A100"]}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up("md")]: {
      paddingTop: "1.5em",
    },
  },
  tagline: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      paddingTop: "0.8em",
    },
  },
  iconContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  iconButton: {
    float: "right",
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: "auto",
  },
  tabs: {
    marginLeft: "auto",
  },
});

const pathMapper = {
  "/": 0,
  "/login": 1,
  "/register": 2,
  "/contact-us": 3,
  "/profile": 0,
  "/chat": 1,
  "/form": 2,
  //"/file": 3,
  "/logout": 3,
  "/users": 0,
  "/chats": 1,
  "/bulk-pdf": 2,
  "/bulk-email": 3,
};

const Topbar = (props) => {
  const [value, setValue] = useState(0);
  const [menuDrawer, setMenuDrawer] = useState(false);
  const [user] = useAuthState(auth);
  const roleId = useSelector((state) => state.user?.roleId);

  const menu = useMemo(() => {
    let mMenu = Menu;
    if (user && roleId) {
      if (roleId === userRoles.USER) {
        mMenu = AppMenu;
      }
      if (roleId === userRoles.ADMIN) {
        mMenu = AdminMenu;
      }
    }

    return mMenu;
  }, [roleId, user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setValue(0);
  }, [menu]);

  useEffect(() => {
    if (pathMapper[props.location.pathname]) {
      setValue(pathMapper[props.location.pathname]);
    } else {
      const mapper = Object.keys(pathMapper).filter((path) => {
        return props.location.pathname.includes(path);
      });

      if (mapper) {
        setValue(pathMapper[mapper[1]]);
      }
    }
  }, [props.location]);

  return (
    <AppBar position="fixed" className={props.classes.appBar}>
      <Toolbar>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} className={props.classes.flex}>
              <div className={props.classes.inline}>
                <Typography variant="h6" color="inherit" noWrap>
                  <Link to="/" className={props.classes.link}>
                    <img width={20} src={logo} alt="" />
                    <span className={props.classes.tagline}>
                      Transition to civilian
                    </span>
                  </Link>
                </Typography>
              </div>
              <React.Fragment>
                <div className={props.classes.tabs}>
                  <div className={props.classes.iconContainer}>
                    <IconButton
                      onClick={() => setMenuDrawer(true)}
                      className={props.classes.iconButton}
                      color="inherit"
                      aria-label="Menu"
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </IconButton>
                  </div>
                  <div className={props.classes.tabContainer}>
                    <SwipeableDrawer
                      anchor="right"
                      open={menuDrawer}
                      onClose={() => setMenuDrawer(false)}
                      onOpen={() => setMenuDrawer(true)}
                    >
                      <AppBar title="Menu" />
                      <Box sx={{ width: 250 }} role="presentation">
                        <List>
                          {menu.map((item) => (
                            <ListItem
                              component={item.external ? MaterialLink : Link}
                              href={item.external ? item.pathname : null}
                              to={
                                item.external
                                  ? null
                                  : {
                                      pathname: item.pathname,
                                      search: props.location.search,
                                    }
                              }
                              button
                              key={item.label}
                            >
                              <ListItemText primary={item.label} />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </SwipeableDrawer>
                    <Tabs
                      value={value < menu.length ? value : 0}
                      onChange={(event, value) => setValue(value)}
                    >
                      {menu.map((item, index) => (
                        <Tab
                          key={index}
                          component={Link}
                          href={item.external ? item.pathname : null}
                          to={{
                            pathname: item.pathname,
                            search: props.location.search,
                          }}
                          classes={{ root: props.classes.tabItem }}
                          label={item.label}
                        />
                      ))}
                    </Tabs>
                  </div>
                </div>
              </React.Fragment>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(withStyles(styles)(Topbar));
