import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/styles/withStyles";
import {
  Card,
  CardContent,
  CircularProgress,
  colors,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faArrowCircleDown,
  faFilePdf,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import { typeOfClearances } from "../consts/typeOfClearances";
import { educationLevels } from "../consts/educationLevels";
import { typeOfMOS } from "../consts/typeOfMOS";
import { useFormControls } from "../validations/form";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../helpers/firebase";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const styles = (theme) => ({
  heading: {
    textAlign: "left",
    margin: "40px 0 0",
    padding: "0 15px",
  },
  heading2: {
    textAlign: "left",
    margin: "20px 0 0",
    padding: "0 15px",
  },
  subtitle: {
    padding: "0 15px",
  },
  form: {
    width: "100%",
  },
  field: {
    marginBottom: "25px",
  },
  helperText: {
    marginBottom: "25px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fieldRow: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    alignItems: "center",
  },
  iconBtnWrapper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    alignItems: "center",
    display: "flex",
    textAlign: "center",
  },
  jobTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    color: colors.orange["300"],
    display: "flex",
  },
  mt25: {
    marginTop: "25px",
  },
  submit: {
    margin: "25px 0 40px",
  },
  flex: {
    display: "flex",
    width: "100%",
  },
  iconAdd: {
    color: `${theme.palette.success.main}`,
  },
  iconRemove: {
    color: `${theme.palette.error.main}`,
  },
  uploadCard: {
    backgroundColor: `${theme.palette.primary.main}`,
    marginTop: "25px",
  },
  uploadCardContent: {
    textAlign: "center",
    paddingTop: "30px",
  },
  uploadCardInfo: {
    backgroundColor: `${theme.palette.primary.main}`,
    marginTop: "10px",
  },
  uploadCardInfoContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  fileDataContainer: {
    flex: 1,
    overflow: "hidden",
  },
  fileName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginTop: "0",
    marginBottom: "5px",
  },
  cancelButton: {
    width: "30px",
    height: "30px",
  },
  downloadButtonWrapper: {
    marginTop: "50px",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VALID_FILE_SIZE = 5 * 1024 * 1024;

const Form = (props) => {
  const [user] = useAuthState(auth);
  const [fileData, setFileData] = useState(null);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    values,
    setValues,
    errors,
    message,
    setMessage,
    messageOpen,
    setMessageOpen,
    messageType,
    setMessageType,
  } = useFormControls(user?.uid);

  const getJobTitle = useCallback((code) => {
    const tile = typeOfMOS.find((type) => type.MOS === code);
    return tile?.Title;
  }, []);

  useEffect(() => {
    if (messageOpen) {
      setSubmitDialogOpen(true);
    }
  }, [messageOpen]);

  useEffect(() => {
    db.collection("Users")
      .doc(user?.uid)
      .get()
      .then((response) => {
        let data = response.data();
        setValues({
          mobile: data.mobile || "",
          typeOfClearance: data.type_of_clearance || 0,
          educationLevel: data.education_level || 0,
          resumeFileURL: data.resume_file_url,
          skills: data.skills || [
            {
              code: "",
              years: "",
            },
          ],
          certifications: data.certifications || [
            {
              certification: "",
            },
          ],
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [setValues, user]);

  const addSkill = useCallback(() => {
    let temp = values.skills;
    temp.push({ code: "", years: "" });

    setValues({
      ...values,
      temp,
    });
  }, [setValues, values]);

  const removeSkill = useCallback(
    (index) => {
      let temp = values.skills.splice(index, 1);

      setValues({
        ...values,
        temp,
      });
    },
    [setValues, values]
  );

  const addCertification = useCallback(() => {
    let temp = values.certifications;
    temp.push({ certification: "" });

    setValues({
      ...values,
      temp,
    });
  }, [setValues, values]);

  const removeCertification = useCallback(
    (index) => {
      let temp = values.certifications.splice(index, 1);

      setValues({
        ...values,
        temp,
      });
    },
    [setValues, values]
  );

  const handleSkillValue = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      let temp = (values.skills[index][name] = value);

      setValues({
        ...values,
        temp,
      });
    },
    [setValues, values]
  );

  const handleCertificationValue = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      let temp = (values.certifications[index][name] = value);

      setValues({
        ...values,
        temp,
      });
    },
    [setValues, values]
  );

  const handleFileUpload = useCallback(
    ({ target }) => {
      const fileReader = new FileReader();

      if (target.files) {
        const file = target.files[0];
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
          if (file.type !== "application/pdf" || file.size > VALID_FILE_SIZE) {
            setMessage("File size or type is invalid");
            setMessageType("error");
            setMessageOpen(true);
            return;
          }
          setFileData(file);

          const fileRef = storage.ref().child(`file/${user.uid}.pdf`);
          const uploadTask = fileRef.put(file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setFileUploadProgress(progress);
            },
            (error) => {
              setMessage(error.message);
              setMessageType("error");
              setMessageOpen(true);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                setValues((prevState) => ({
                  ...prevState,
                  resumeFileURL: downloadURL,
                }));

                db.collection("Users")
                  .doc(user.uid)
                  .update({
                    resume_file_url: downloadURL,
                  })
                  .then((data) => {
                    setMessage("Resume submitted successfully");
                    setMessageType("success");
                    setMessageOpen(true);
                  })
                  .catch(function (error) {
                    setMessage(error.message);
                    setMessageType("error");
                    setMessageOpen(true);
                  });
              });
            }
          );
        };
      }
    },
    [setMessage, setMessageOpen, setMessageType, setValues, user]
  );

  const cancelFileUpload = useCallback(() => {
    setFileData(null);
    setFileUploadProgress(0);
  }, []);

  const bytesToMegaBytes = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  };

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center" spacing={4}>
        <Grid item md={6}>
          <Typography variant="h4" className={props.classes.heading}>
            Profile
          </Typography>
          <Typography variant="subtitle1" className={props.classes.subtitle}>
            Please fill out all the required fields.
          </Typography>

          <div className={props.classes.mt25}>
            <form
              onSubmit={handleFormSubmit}
              className={props.classes.form}
              noValidate
              autoComplete="off"
            >
              <TextField
                onChange={handleInputValue}
                onBlur={handleInputValue}
                name="mobile"
                label="Mobile"
                fullWidth
                className={props.classes.field}
                variant="outlined"
                value={values["mobile"] || ""}
                {...(errors["mobile"] && {
                  error: true,
                  helperText: errors["mobile"],
                })}
              />

              <FormControl fullWidth error={!!errors["typeOfClearance"]}>
                <Select
                  onChange={handleInputValue}
                  onBlur={handleInputValue}
                  name="typeOfClearance"
                  fullWidth
                  value={values["typeOfClearance"] || 0}
                  variant="outlined"
                >
                  {typeOfClearances.map((item, index) => (
                    <MenuItem key={`menu-item-${index}`} value={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className={props.classes.helperText}>
                  {errors["typeOfClearance"] ?? ""}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth error={!!errors["educationLevel"]}>
                <Select
                  onChange={handleInputValue}
                  onBlur={handleInputValue}
                  name="educationLevel"
                  fullWidth
                  value={values["educationLevel"] || 0}
                  variant="outlined"
                >
                  {educationLevels.map((item, index) => (
                    <MenuItem key={`menu-item-${index}`} value={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className={props.classes.helperText}>
                  {errors["educationLevel"] ?? ""}
                </FormHelperText>
              </FormControl>

              <Typography variant="h6" className={props.classes.heading2}>
                Skills
              </Typography>
              <Typography
                variant="subtitle1"
                className={props.classes.subtitle}
              >
                Years of experience with MOS
              </Typography>

              {values.skills.map((item, index) => (
                <Grid
                  key={`skill-grid-${index}`}
                  container
                  className={props.classes.mt25}
                >
                  <Grid className={props.classes.fieldRow} item md={5}>
                    <TextField
                      onChange={(event) => handleSkillValue(event, index)}
                      key={index}
                      label="MOS"
                      name="code"
                      fullWidth
                      variant="outlined"
                      value={item.code || ""}
                    />
                  </Grid>

                  <Grid className={props.classes.fieldRow} item md={5}>
                    <TextField
                      onChange={(event) => handleSkillValue(event, index)}
                      key={index}
                      label="Years of exp"
                      name="years"
                      type="number"
                      fullWidth
                      variant="outlined"
                      value={item.years || ""}
                    />
                  </Grid>
                  <Grid className={props.classes.iconBtnWrapper} item md={2}>
                    {index === 0 ? (
                      <IconButton
                        onClick={addSkill}
                        className={props.classes.iconAdd}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => removeSkill(index)}
                        className={props.classes.iconRemove}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid className={props.classes.jobTitle} item md={12}>
                    {getJobTitle(item.code)}
                  </Grid>
                </Grid>
              ))}

              <Typography variant="h6" className={props.classes.heading2}>
                Certifications
              </Typography>
              {values.certifications.map((item, index) => (
                <Grid
                  key={`certification-grid-${index}`}
                  container
                  className={props.classes.mt25}
                >
                  <Grid className={props.classes.fieldRow} item md={10}>
                    <TextField
                      onChange={(event) =>
                        handleCertificationValue(event, index)
                      }
                      key={index}
                      label="Certification"
                      name="certification"
                      fullWidth
                      variant="outlined"
                      value={item.certification || ""}
                    />
                  </Grid>
                  <Grid className={props.classes.iconBtnWrapper} item md={2}>
                    {index === 0 ? (
                      <IconButton
                        onClick={addCertification}
                        className={props.classes.iconAdd}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => removeCertification(index)}
                        className={props.classes.iconRemove}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ))}

              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={props.classes.submit}
                disabled={!formIsValid()}
              >
                Transition
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item md={6}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h4" className={props.classes.heading}>
                Resume File <small>(optional)</small>
              </Typography>
              <Typography
                variant="subtitle1"
                className={props.classes.subtitle}
              >
                Please upload your resume in "PDF" format
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {values.resumeFileURL ? (
                <Grid item className={props.classes.downloadButtonWrapper}>
                  <Button
                    color="secondary"
                    variant="contained"
                    endIcon={<FontAwesomeIcon icon={faArrowCircleDown} />}
                    href={values.resumeFileURL}
                    target="_blank"
                  >
                    Download
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Card className={props.classes.uploadCard}>
            <CardContent className={props.classes.uploadCardContent}>
              <FontAwesomeIcon icon={faFilePdf} size="5x" />
              <p>Maximum file size is {bytesToMegaBytes(VALID_FILE_SIZE)}</p>

              <input
                accept="application/pdf"
                type="file"
                hidden
                id="resume-file-input"
                onChange={handleFileUpload}
              />
              <label htmlFor="resume-file-input">
                <Button
                  disabled={!!fileUploadProgress}
                  size="large"
                  variant="outlined"
                  component="span"
                >
                  Select File
                </Button>
              </label>
            </CardContent>
          </Card>

          {fileData ? (
            <Card className={props.classes.uploadCardInfo}>
              <CardContent>
                <div className={props.classes.uploadCardInfoContent}>
                  <div className={props.classes.fileDataContainer}>
                    <p className={props.classes.fileName}>{fileData.name}</p>
                    <div>File size: {bytesToMegaBytes(fileData.size)}</div>
                  </div>
                  <IconButton
                    size="small"
                    className={props.classes.cancelButton}
                    onClick={cancelFileUpload}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </div>

                <LinearProgress
                  color="secondary"
                  variant="determinate"
                  value={fileUploadProgress}
                />
              </CardContent>
            </Card>
          ) : null}
        </Grid>
      </Grid>

      <Dialog
        open={submitDialogOpen}
        onClose={() => setSubmitDialogOpen(false)}
        scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title">Submission Result</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText color="white">
            Your resume submitted successfully. You'll receive an email when
            your resume confirmed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSubmitDialogOpen(false)}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={messageOpen}
        autoHideDuration={6000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert onClose={() => setMessageOpen(false)} severity={messageType}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withRouter(withStyles(styles)(Form));
