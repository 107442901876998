import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useFormControls } from "../validations/profile";
import MuiAlert from "@material-ui/lab/Alert";
import { grey } from "@material-ui/core/colors";
import { auth, db, storage } from "../helpers/firebase";
import defaultAvatar from "../assets/images/avatar.png";

const styles = (theme) => ({
  field: {
    marginBottom: "25px",
  },
  heading: {
    textAlign: "center",
    margin: "40px 0",
  },
  mt10: {
    marginTop: "10px",
    display: "block",
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    background: grey[400],
  },
  avatarBtn: {
    marginBottom: theme.spacing(7),
    padding: 0,
    borderRadius: "200px !important",
  },
  loader: {
    minHeight: "100vh",
  },
});

const inputFieldValues = [
  {
    name: "firstName",
    label: "First Name",
    id: "first-name",
  },
  {
    name: "lastName",
    label: "Last Name",
    id: "last-name",
  },
  {
    name: "email",
    label: "Email",
    id: "email",
  },
  {
    name: "address",
    label: "Address",
    id: "address",
  },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VALID_FILE_SIZE = 2 * 1024 * 1024;

const Profile = (props) => {
  const [user] = useAuthState(auth);
  const [avatarUploadProgress, setAvatarUploadProgress] = useState(0);

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    values,
    setValues,
    errors,
    message,
    setMessage,
    messageOpen,
    setMessageOpen,
    messageType,
    setMessageType,
  } = useFormControls(user?.uid);

  useEffect(() => {
    db.collection("Users")
      .doc(user?.uid)
      .get()
      .then((response) => {
        let data = response.data();
        setValues({
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          address: data.address,
          imageURL: data.image_url,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [setValues, user]);

  const handleImageUpload = useCallback(
    ({ target }) => {
      const fileReader = new FileReader();

      if (target.files) {
        const file = target.files[0];
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
          if (file.size > VALID_FILE_SIZE) {
            setMessage("File size or type is invalid");
            setMessageType("error");
            setMessageOpen(true);
            return;
          }

          const re = /(?:\.([^.]+))?$/;
          const ext = re.exec(file.name)[1];

          const fileRef = storage.ref().child(`avatar/${user.uid}.${ext}`);
          const uploadTask = fileRef.put(file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setAvatarUploadProgress(progress);
            },
            (error) => {
              setMessage(error.message);
              setMessageType("error");
              setMessageOpen(true);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                setValues((prevState) => ({
                  ...prevState,
                  imageURL: downloadURL,
                }));

                db.collection("Users")
                  .doc(user.uid)
                  .update({
                    image_url: downloadURL,
                  })
                  .then((data) => {})
                  .catch(function (error) {
                    setMessage(error.message);
                    setMessageType("error");
                    setMessageOpen(true);
                  });
              });
            }
          );
        };
      }
    },
    [setMessage, setMessageOpen, setMessageType, setValues, user.uid]
  );

  return (
    <Container maxWidth="md">
      {values ? (
        <Grid container justifyContent="center">
          <Grid item md={7}>
            <Typography variant="h5" className={props.classes.heading}>
              <div hidden>Register a new account</div>
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Grid container justifyContent="center">
              <input
                accept="image/png, image/jpeg"
                type="file"
                hidden
                id="avatar-input"
                onChange={handleImageUpload}
              />

              <label htmlFor="avatar-input">
                <Button className={props.classes.avatarBtn} component="span">
                  <Avatar
                    src={values.imageURL ?? defaultAvatar}
                    className={props.classes.large}
                  >
                    &nbsp;
                  </Avatar>
                </Button>
              </label>
            </Grid>

            <form
              className={props.classes.form}
              onSubmit={handleFormSubmit}
              noValidate
              autoComplete="off"
            >
              {inputFieldValues.map((inputFieldValue, index) => {
                return (
                  <TextField
                    key={index}
                    onBlur={handleInputValue}
                    onChange={handleInputValue}
                    fullWidth
                    name={inputFieldValue.name}
                    label={inputFieldValue.label}
                    className={props.classes.field}
                    variant="outlined"
                    value={values[inputFieldValue.name] || ""}
                    type={inputFieldValue.type ?? "text"}
                    {...(errors[inputFieldValue.name] && {
                      error: true,
                      helperText: errors[inputFieldValue.name],
                    })}
                  />
                );
              })}

              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={props.classes.margin}
                disabled={!formIsValid()}
              >
                Update profile
              </Button>
            </form>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={props.classes.loader}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={messageOpen}
        autoHideDuration={6000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert onClose={() => setMessageOpen(false)} severity={messageType}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withRouter(withStyles(styles)(Profile));
