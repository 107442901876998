import { useState } from "react";
import { auth, db } from "../helpers/firebase";
import { userRoles } from "../consts/userRoles";

const initialFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  repeatPassword: "",
  formSubmitted: false,
  success: false,
};

export const useFormControls = () => {
  // We'll update "values" as the form updates
  const [values, setValues] = useState(initialFormValues);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = useState({});
  const [registerError, setRegisterError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [registered, setRegistered] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    for (const [key, value] of Object.entries(fieldValues)) {
      temp[key] = value ? "" : "This field is required.";
    }

    /*if ("firstName" in fieldValues)
            temp.firstName = fieldValues.firstName ? "" : "This field is required."
            */

    if ("email" in fieldValues) {
      //temp.email = fieldValues.email ? "" : "This field is required."
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("repeatPassword" in fieldValues) {
      if (fieldValues.repeatPassword)
        temp.repeatPassword =
          fieldValues.repeatPassword === values.password
            ? ""
            : "Your password and repeat password do not match.";
    }

    setErrors({
      ...temp,
    });
  };
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formIsValid()) {
      auth
        .createUserWithEmailAndPassword(values.email, values.password)
        .then((data) => {
          let date = new Date();
          db.collection("Users")
            .doc(data.user.uid)
            .set({
              email: data.user.email,
              uid: data.user.uid,
              created_at: { seconds: date.getTime() / 1000, nanoseconds: 0 },
              first_name: values.firstName,
              last_name: values.lastName,
              role_id: userRoles.USER,
              image_url: "",
              mobile: "",
              platform: "web",
            })
            .then((data) => {
              setRegistered(true);
            })
            .catch(function (error) {
              setRegisterError(error.message);
              setErrorOpen(true);
            });
        })
        .catch(function (error) {
          let errorCode = error.code;
          let errorMessage = error.message;

          setRegisterError(errorMessage);
          setErrorOpen(true);
          console.log(errorCode, errorMessage);
        });
    }
  };
  const formIsValid = (fieldValues = values) => {
    return (
      fieldValues.firstName &&
      fieldValues.email &&
      fieldValues.password &&
      fieldValues.repeatPassword &&
      Object.values(errors).every((x) => x === "")
    );
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    registerError,
    errorOpen,
    setErrorOpen,
    registered,
  };
};
