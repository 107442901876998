import { getJsDate } from "../utiles/time-utiles";

export class ChatModel {
  constructor(chatData) {
    this.roomId = chatData.room_id;
    this.adminFirstName = chatData.admin_first_name;
    this.adminlastName = chatData.admin_last_name;
    this.adminImageURL = chatData.admin_image_url;
    this.adminNewMessageStatus = chatData.admin_new_msg_status ?? false;
    this.userFirstName = chatData.user_first_name;
    this.userLastName = chatData.user_last_name;
    this.userImageURL = chatData.user_image_url;
    this.userNewMessageStatus = chatData.user_new_msg_status ?? false;
    this.lastMessage = chatData.last_message ?? "";
    this.messageTo = chatData.message_to;
    this.createdAt = chatData.created_at
      ? getJsDate(chatData.created_at)
      : new Date();
    this.updatedAt = chatData.updated_at
      ? getJsDate(chatData.updated_at)
      : new Date();
    this.users = chatData.users ?? [];
  }
}
