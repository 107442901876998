import firebase from "../plugins/firebase";

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

if (window.location.hostname === "localhost") {
  auth.useEmulator("http://localhost:9099");
  db.useEmulator("localhost", 8080);
  storage.useEmulator("localhost", 9199);
}

export { auth, db, storage };
