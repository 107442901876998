import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Avatar,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { auth, db } from "../helpers/firebase";
import { UserModel } from "../models";
import { ChatModel } from "../models/chat-model";
import { useAuthState } from "react-firebase-hooks/auth";
import { Alert } from "@material-ui/lab";
import { getDartTimestamp } from "../utiles/time-utiles";
import { Button, Chip, ListItemButton, TextField } from "@mui/material";
import { MessageModel } from "../models/message-model";
import classNames from "classnames";

const styles = (theme) => ({
  chatPanel: {
    height: "calc(100vh - 65px)",
    alignItems: "flex-start",
    display: "flex",
  },
  leftPanel: {
    backgroundColor: `${theme.palette.primary.dark}`,
    height: "100%",
    display: "flex",
    overflowY: "auto",
    "& ul.MuiList-root": {
      width: "100%",
    },
  },
  rightPanel: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: "100%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  activeRoom: {
    backgroundColor: `${theme.palette.primary.main}`,
  },
  message: {
    maxWidth: "fit-content !important",
    marginBottom: "10px",
    padding: "10px !important",
    height: "auto !important",
    "& span": {
      whiteSpace: "normal",
    },
  },
  myMessage: {
    alignSelf: "flex-end",
  },
  messagesWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "20px",
    flex: 1,
    overflowY: "auto",
  },
  formWrapper: {
    display: "flex",
  },
  chatRoomItem: {
    "& .MuiListItemButton-root": {
      width: "100%",
    },
  },
  unReadRoom: {
    width: "8px",
    height: "8px",
    borderRadius: "4px",
    backgroundColor: `${theme.palette.secondary.main}`,
  },
});

const Chats = (props) => {
  const [user] = useAuthState(auth);
  const { id } = useParams();
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [roomId, setRoomId] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [client, setClient] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const messagesRef = useRef(null);

  // error message
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("success");

  const createRoom = useCallback(() => {
    if (admin && client) {
      db.collection("Chatrooms")
        .doc(roomId)
        .set({
          users: [client.uID, admin.uID],
          room_id: client.uID + "_" + admin.uID,
          admin_first_name: admin.firstName,
          admin_last_name: admin.lastName,
          admin_image_url: admin.imageURL,
          admin_new_msg_status: false,
          user_first_name: client.firstName,
          user_last_name: client.lastName,
          user_image_url: client.imageURL,
          user_new_msg_status: true,
          last_message: "",
          message_to: client.uID,
          created_at: getDartTimestamp(new Date()),
          updated_at: getDartTimestamp(new Date()),
        })
        .then()
        .catch((error) => {
          setMessage(error.message);
          setMessageType("error");
          setMessageOpen(true);
        });
    }
  }, [admin, client, roomId]);

  const addMessage = useCallback(() => {
    if (newMessage.length && admin && roomId) {
      db.collection("Chatrooms")
        .doc(roomId)
        .collection("Chats")
        .add({
          message: newMessage,
          time: getDartTimestamp(new Date()),
          sent_by: admin.uID,
        })
        .then((response) => {
          setNewMessage("");
          db.collection("Chatrooms").doc(roomId).update({
            last_message: newMessage,
            user_new_msg_status: true,
          });
        });
    }
  }, [admin, newMessage, roomId]);

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        addMessage();
      }
    },
    [addMessage]
  );

  useEffect(() => {
    db.collection("Users")
      .doc(user?.uid)
      .get()
      .then((response) => {
        let data = response.data();
        let admin = new UserModel(data);
        admin.uID = user.uid;
        setAdmin(admin);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [user]);

  useEffect(() => {
    if (id) {
      db.collection("Users")
        .doc(id)
        .get()
        .then((response) => {
          let data = response.data();
          if (!data) {
            setMessage("User not found");
            setMessageType("error");
            setMessageOpen(true);
          } else {
            let client = new UserModel(data);
            client.uID = id;
            setClient(client);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setMessage(error.message);
          setMessageType("error");
          setMessageOpen(true);
        });
      setRoomId(id + "_" + user?.uid);
    } else {
      setRoomId(null);
    }
  }, [id, user]);

  useEffect(() => {
    db.collection("Chatrooms")
      .orderBy("updated_at", "desc")
      .onSnapshot((response) => {
        let data = response.docs;
        const chats = data.map((doc) => {
          return new ChatModel(doc.data());
        });
        setChats(chats);

        if (roomId) {
          const chat = data.some((doc) => doc.id === roomId);
          if (!chat) {
            createRoom();
          }
        }
      });
  }, [createRoom, roomId]);

  useEffect(() => {
    if (roomId) {
      db.collection("Chatrooms")
        .doc(roomId)
        .collection("Chats")
        .orderBy("time", "asc")
        .onSnapshot((response) => {
          let data = response.docs;
          const messages = data.map((doc) => {
            return new MessageModel(doc.id, doc.data());
          });
          setMessages(messages);
          db.collection("Chatrooms").doc(roomId).update({
            admin_new_msg_status: false,
          });
          messagesRef.current?.scrollIntoView({ behavior: "smooth" });
        });
    }
  }, [roomId]);

  return (
    <Container>
      <Grid container alignItems="center" className={props.classes.chatPanel}>
        <Grid item xs={3} className={props.classes.leftPanel}>
          <List>
            {chats.map((chat) => (
              <ListItem
                className={classNames(props.classes.chatRoomItem, {
                  [`${props.classes.activeRoom}`]: chat.roomId === roomId,
                })}
                key={chat.roomId}
              >
                <ListItemButton onClick={() => setRoomId(chat.roomId)}>
                  <ListItemAvatar>
                    <Avatar alt={chat.userFirstName} src={chat.userImageURL} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={chat.userFirstName + " " + chat.userLastName}
                    secondary={
                      <React.Fragment>
                        <Typography noWrap>
                          {chat.lastMessage
                            ? ` — ${chat.lastMessage}`
                            : ` — ${chat.createdAt.toLocaleString()}`}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItemButton>
                {chat.adminNewMessageStatus ? (
                  <div className={props.classes.unReadRoom} />
                ) : null}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={9} className={props.classes.rightPanel}>
          <div className={props.classes.messagesWrapper}>
            {admin &&
              messages.map((message) => (
                <Chip
                  className={classNames(props.classes.message, {
                    [`${props.classes.myMessage}`]:
                      message.sentBy === admin?.uID,
                  })}
                  key={message.id}
                  label={message.message}
                  color={message.sentBy === admin?.uID ? "primary" : "success"}
                />
              ))}
            <div ref={messagesRef} />
          </div>
          {roomId ? (
            <div className={props.classes.formWrapper}>
              <TextField
                variant="outlined"
                size="large"
                value={newMessage}
                onChange={(event) => setNewMessage(event.target.value)}
                fullWidth
                onKeyPress={(event) => keyPress(event)}
              />
              <Button color="primary" size="large" onClick={addMessage}>
                Send
              </Button>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={messageOpen}
        autoHideDuration={6000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert onClose={() => setMessageOpen(false)} severity={messageType}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withRouter(withStyles(styles)(Chats));
