import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import {
  createTheme,
  makeStyles,
  StylesProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Routes from "./plugins/routes";
import "./assets/css/App.css";
import Topbar from "./components/Topbar";
import { HashRouter } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { CircularProgress, Grid } from "@material-ui/core";
import { auth } from "./helpers/firebase";
import { Provider } from "react-redux";
import store from "./plugins/store";
import { green } from "@material-ui/core/colors";

//import {useCollectionData} from 'react-firebase-hooks/firestore';

//const firestore = firebase.firestore();

const theme = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#002365",
    },
    text: {
      primary: "#ffffff",
    },
    primary: {
      main: "#00133b",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-focused": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important",
      borderRadius: "30px !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
    "& .MuiButton-root": {
      borderRadius: "50px",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      borderRadius: "50px",
    },
    "& .MuiButton-containedSizeLarge": {
      padding: "12px 22px",
    },
    "& .MuiMenu-paper": {
      backgroundColor: "#00133b",
    },
    "& .MuiSelect-select:focus": {
      borderRadius: "50px",
    },
  },
  main: {
    paddingTop: "65px",
    color: "#FFFFFF",
  },
  loader: {
    minHeight: "100vh",
  },
}));

function App() {
  // eslint-disable-next-line no-unused-vars
  const [user, loading] = useAuthState(auth);
  const classes = useStyles();

  return (
    <Provider store={store}>
      <StylesProvider>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            {!loading ? (
              <div>
                <HashRouter>
                  <Topbar />
                </HashRouter>

                <Container maxWidth="xl" className={classes.main}>
                  <Routes />
                </Container>
              </div>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.loader}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
          </div>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
}

export default App;
