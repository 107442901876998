const Menu = [
  {
    label: "Home",
    pathname: "/",
  },
  {
    label: "Login",
    pathname: "/login",
  },
  {
    label: "Register",
    pathname: "/register",
  },
  {
    label: "Contact Us",
    pathname: "/contact-us",
  },
];

export default Menu;
