import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import { ListItemButton, Typography } from "@mui/material";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import { typeOfClearances } from "../consts/typeOfClearances";
import { educationLevels } from "../consts/educationLevels";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { db, storage } from "../helpers/firebase";
import { Alert } from "@material-ui/lab";
import TextSelector from "text-selection-react";
import { userRoles } from "../consts/userRoles";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = (theme) => ({
  container: {
    height: "calc(100vh - 65px)",
    alignItems: "flex-start",
    display: "flex",
  },
  leftPanel: {
    backgroundColor: `${theme.palette.primary.dark}`,
    height: "100%",
    "& ul.MuiList-root": {
      width: "100%",
    },
    "& li.MuiListItem-root": {
      padding: 0,
    },
  },
  pdfViewer: {
    backgroundColor: `${theme.palette.primary.light}`,
    height: "100%",
    overflowY: "auto",
  },
  rightPanel: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: "100%",
    overflowY: "auto",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  selectFileLabel: {
    padding: "10px",
    display: "block",
  },
  activeFile: {
    backgroundColor: `${theme.palette.primary.main}`,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  formWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowY: "auto",
  },
  fileItem: {
    "& .MuiListItemButton-root": {
      width: "100%",
    },
  },
  field: {
    marginBottom: "10px",
    marginTop: "10px",
  },
});

const textFields = [
  {
    name: "firstName",
    label: "First Name",
    id: "first-name",
  },
  {
    name: "lastName",
    label: "Last Name",
    id: "last-name",
  },
  {
    name: "email",
    label: "Email",
    id: "email",
  },
  {
    name: "address",
    label: "Address",
    id: "address",
  },
  {
    name: "mobile",
    label: "Mobile",
    id: "mobile",
  },
];

const BulkPdf = (props) => {
  const [files, setFiles] = useState([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("success");

  const uploadFile = useCallback(
    (fileQueue) => {
      const index = files.findIndex((file) => {
        return file.data.id === fileQueue.id;
      });
      if (index < 0 || files[index]?.progress) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsDataURL(fileQueue.file);
      fileReader.onload = (e) => {
        const fileRef = storage.ref().child(`file/${fileQueue.id}.pdf`);
        const uploadTask = fileRef.put(fileQueue.file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let temp = files.slice();
            temp[index].progress = progress;
            setFiles(temp);
          },
          (error) => {
            setMessage(error.message);
            setMessageType("error");
            setMessageOpen(true);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              db.collection("Users")
                .doc(fileQueue.id)
                .update({
                  resume_file_url: downloadURL,
                })
                .then((data) => {
                  setMessage("File uploaded successfully");
                  setMessageType("success");
                  setMessageOpen(true);
                })
                .catch(function (error) {
                  setMessage(error.message);
                  setMessageType("error");
                  setMessageOpen(true);
                });
            });
          }
        );
      };
    },
    [files]
  );

  const handleFiles = useCallback(({ target }) => {
    const files = [];
    Array.from(target.files).forEach((file) => {
      files.push({
        file: file,
        data: {
          certifications: [{ certification: "" }],
          skills: [{ code: "", years: "" }],
        },
      });
    });
    setFiles(files);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const bytesToMegaBytes = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  };

  const handleFileSelect = useCallback((index) => {
    setSelectedFileIndex(index);
  }, []);

  const setInput = useCallback(
    (name, value) => {
      let temp = files.slice();
      temp[selectedFileIndex].data[name] = value;
      setFiles(temp);
    },
    [files, selectedFileIndex]
  );

  const handleInputValue = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInput(name, value);
    },
    [setInput]
  );

  const addSkill = useCallback(
    (code = "", years = "") => {
      let temp = files.slice();
      temp[selectedFileIndex].data.skills.push({ code: code, years: years });

      setFiles(temp);
    },
    [files, selectedFileIndex]
  );

  const removeSkill = useCallback(
    (index) => {
      let temp = files.slice();
      temp[selectedFileIndex].data.skills.splice(index, 1);

      setFiles(temp);
    },
    [files, selectedFileIndex]
  );

  const handleSkillValue = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      let temp = files.slice();
      temp[selectedFileIndex].data.skills[index][name] = value;

      setFiles(temp);
    },
    [files, selectedFileIndex]
  );

  const addCertification = useCallback(
    (certification = "") => {
      let temp = files.slice();
      temp[selectedFileIndex].data.certifications.push({
        certification: certification,
      });

      setFiles(temp);
    },
    [files, selectedFileIndex]
  );

  const removeCertification = useCallback(
    (index) => {
      let temp = files.slice();
      temp[selectedFileIndex].data.certifications.splice(index, 1);

      setFiles(temp);
    },
    [files, selectedFileIndex]
  );

  const handleCertificationValue = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      let temp = files.slice();
      temp[selectedFileIndex].data.certifications[index][name] = value;

      setFiles(temp);
    },
    [files, selectedFileIndex]
  );

  const textSelectEvents = useMemo(() => {
    const events = [];
    textFields.forEach((textField) => {
      events.push({
        text: textField.label,
        handler: (html, text) => setInput(textField.name, text),
      });
    });

    events.push({
      text: "Certification",
      handler: (html, text) => {
        let temp = files.slice();
        temp[selectedFileIndex].data.certifications.forEach((certification) => {
          if (certification.certification === "") {
            certification.certification = text;
            setFiles(temp);
          } else {
            addCertification(text);
          }
        });
      },
    });

    events.push({
      text: "Skill",
      handler: (html, text) => {
        let temp = files.slice();
        temp[selectedFileIndex].data.skills.forEach((skill) => {
          if (skill.code === "") {
            skill.code = text;
            setFiles(temp);
          } else {
            addSkill(text);
          }
        });
      },
    });

    return events;
  }, [addCertification, addSkill, files, selectedFileIndex, setInput]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const inputs = {
      first_name: files[selectedFileIndex].data.firstName ?? "",
      last_name: files[selectedFileIndex].data.lastName ?? "",
      address: files[selectedFileIndex].data.address ?? "",
      email: files[selectedFileIndex].data.email ?? "",
      mobile: files[selectedFileIndex].data.mobile ?? "",
      role_id: userRoles.USER,
      education_level:
        files[selectedFileIndex].data.educationLevel ?? educationLevels[0],
      type_of_clearance:
        files[selectedFileIndex].data.typeOfClearance ?? typeOfClearances[0],
      skills: files[selectedFileIndex].data.skills,
      certifications: files[selectedFileIndex].data.certifications,
      image_url: "",
      platform: "web",
    };

    if (files[selectedFileIndex].data.id) {
      db.collection("Users")
        .doc(files[selectedFileIndex].data.id)
        .update(inputs)
        .then((data) => {
          setMessage("User data update was successful");
          setMessageType("success");
          setMessageOpen(true);
        })
        .catch(function (error) {
          setMessage(error.message);
          setMessageType("error");
          setMessageOpen(true);
        });
    } else {
      db.collection("Users")
        .add(inputs)
        .then((data) => {
          setMessage("Form submit was successful");
          setMessageType("success");
          setMessageOpen(true);

          db.collection("Users").doc(data.id).update({
            uid: data.id,
          });
          let temp = files.slice();
          temp[selectedFileIndex].data.id = data.id;
          setFiles(temp);

          uploadFile({ file: files[selectedFileIndex].file, id: data.id });
        })
        .catch(function (error) {
          setMessage(error.message);
          setMessageType("error");
          setMessageOpen(true);
        });
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container className={props.classes.container}>
        <Grid item xs={2} className={props.classes.leftPanel}>
          <div>
            <input
              accept="application/pdf"
              type="file"
              hidden
              id="file-input"
              onChange={handleFiles}
              multiple
            />
            <label
              className={props.classes.selectFileLabel}
              htmlFor="file-input"
            >
              <Button
                color="secondary"
                variant="contained"
                component="span"
                fullWidth
              >
                Select Files
              </Button>
            </label>
          </div>
          <div>
            <List>
              {files.map((file, index) => (
                <ListItem
                  className={classNames(props.classes.fileItem, {
                    [`${props.classes.activeFile}`]:
                      index === selectedFileIndex,
                  })}
                  key={`file-item-${index}`}
                >
                  <ListItemButton onClick={() => handleFileSelect(index)}>
                    <ListItemText
                      primary={file.file.name}
                      secondary={
                        <React.Fragment>
                          <Typography>
                            {bytesToMegaBytes(file.file.size)}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    {file.progress ? (
                      <ListItemIcon>
                        <CircularProgress
                          color="secondary"
                          variant="determinate"
                          value={file.progress}
                        />
                      </ListItemIcon>
                    ) : null}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid item xs={7} className={props.classes.pdfViewer}>
          <TextSelector events={textSelectEvents} />
          {selectedFileIndex !== null ? (
            <Document
              file={files[selectedFileIndex].file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={778}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          ) : null}
        </Grid>
        <Grid item xs={3} className={props.classes.rightPanel}>
          {selectedFileIndex !== null ? (
            <form
              onSubmit={handleFormSubmit}
              className={props.classes.form}
              noValidate
              autoComplete="off"
            >
              <div className={props.classes.formWrapper}>
                <Typography variant="h6">Profile</Typography>
                {textFields.map((field) => (
                  <TextField
                    key={field.id}
                    onChange={handleInputValue}
                    name={field.name}
                    label={field.label}
                    size="small"
                    fullWidth
                    className={props.classes.field}
                    variant="outlined"
                    value={files[selectedFileIndex].data[field.name] ?? ""}
                  />
                ))}

                <FormControl size="small" fullWidth>
                  <Select
                    onChange={handleInputValue}
                    name="typeOfClearance"
                    fullWidth
                    value={
                      files[selectedFileIndex].data["typeOfClearance"] ?? 0
                    }
                    className={props.classes.field}
                    variant="outlined"
                  >
                    {typeOfClearances.map((item, index) => (
                      <MenuItem key={`menu-item-${index}`} value={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl size="small" fullWidth>
                  <Select
                    onChange={handleInputValue}
                    name="educationLevel"
                    fullWidth
                    value={files[selectedFileIndex].data["educationLevel"] ?? 0}
                    className={props.classes.field}
                    variant="outlined"
                  >
                    {educationLevels.map((item, index) => (
                      <MenuItem key={`menu-item-${index}`} value={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography variant="h6" className={props.classes.heading2}>
                    Skills
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={props.classes.subtitle}
                  >
                    Years of experience with MOS
                  </Typography>

                  {files[selectedFileIndex].data.skills.map((item, index) => (
                    <Grid
                      key={`skill-grid-${index}`}
                      container
                      alignItems="center"
                    >
                      <Grid className={props.classes.fieldRow} item md={5}>
                        <TextField
                          onChange={(event) => handleSkillValue(event, index)}
                          key={index}
                          label="MOS"
                          name="code"
                          fullWidth
                          className={props.classes.field}
                          size="small"
                          variant="outlined"
                          value={item.code || ""}
                        />
                      </Grid>

                      <Grid className={props.classes.fieldRow} item md={5}>
                        <TextField
                          onChange={(event) => handleSkillValue(event, index)}
                          key={index}
                          label="Years of exp"
                          name="years"
                          type="number"
                          fullWidth
                          className={props.classes.field}
                          size="small"
                          variant="outlined"
                          value={item.years || ""}
                        />
                      </Grid>
                      <Grid
                        className={props.classes.iconBtnWrapper}
                        item
                        md={2}
                      >
                        {index === 0 ? (
                          <IconButton
                            onClick={() => addSkill()}
                            className={props.classes.iconAdd}
                          >
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => removeSkill(index)}
                            className={props.classes.iconRemove}
                          >
                            <FontAwesomeIcon icon={faMinusCircle} />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}

                  <Typography variant="h6" className={props.classes.heading2}>
                    Certifications
                  </Typography>
                  {files[selectedFileIndex].data.certifications?.map(
                    (item, index) => (
                      <Grid
                        key={`certification-grid-${index}`}
                        container
                        alignItems="center"
                      >
                        <Grid className={props.classes.fieldRow} item md={10}>
                          <TextField
                            onChange={(event) =>
                              handleCertificationValue(event, index)
                            }
                            key={index}
                            label="Certification"
                            name="certification"
                            fullWidth
                            className={props.classes.field}
                            size="small"
                            variant="outlined"
                            value={item.certification || ""}
                          />
                        </Grid>
                        <Grid
                          className={props.classes.iconBtnWrapper}
                          item
                          md={2}
                        >
                          {index === 0 ? (
                            <IconButton
                              onClick={() => addCertification("")}
                              className={props.classes.iconAdd}
                            >
                              <FontAwesomeIcon icon={faPlusCircle} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => removeCertification(index)}
                              className={props.classes.iconRemove}
                            >
                              <FontAwesomeIcon icon={faMinusCircle} />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    )
                  )}
                </FormControl>
              </div>
              <div>
                <Button
                  color="secondary"
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </form>
          ) : null}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={messageOpen}
        autoHideDuration={6000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert onClose={() => setMessageOpen(false)} severity={messageType}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withRouter(withStyles(styles)(BulkPdf));
