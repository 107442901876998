const BottomMenu = [
  {
    label: "Who We Are?",
    pathname: "/who-we-are",
  },
  {
    label: "What WE Do?",
    pathname: "/what-we-do",
  },
  {
    label: "Contact Us",
    pathname: "/contact-us",
  },
];

export default BottomMenu;
