import { getJsTimestamp } from "../utiles/time-utiles";

export class MessageModel {
  constructor(id, messageData) {
    this.id = id;
    this.message = messageData.message;
    this.sentBy = messageData.sent_by;
    this.time = messageData.time
      ? getJsTimestamp(messageData.time)
      : new Date();
  }
}
