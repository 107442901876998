import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Avatar,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  TextField,
  withStyles,
} from "@material-ui/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../helpers/firebase";
import { Alert } from "@material-ui/lab";
import { getDartTimestamp } from "../utiles/time-utiles";
import { ChatModel } from "../models/chat-model";
import { MessageModel } from "../models/message-model";
import classNames from "classnames";
import { Badge, Button, Chip, ListItemButton, Typography } from "@mui/material";

const styles = (theme) => ({
  chatPanel: {
    height: "calc(100vh - 65px)",
    alignItems: "flex-start",
    display: "flex",
  },
  leftPanel: {
    backgroundColor: `${theme.palette.primary.dark}`,
    height: "100%",
    display: "flex",
    overflowY: "auto",
    "& ul.MuiList-root": {
      width: "100%",
    },
  },
  rightPanel: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: "100%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  activeRoom: {
    backgroundColor: `${theme.palette.primary.main}`,
  },
  message: {
    maxWidth: "fit-content !important",
    marginBottom: "10px",
    padding: "10px !important",
    height: "auto !important",
    "& span": {
      whiteSpace: "normal",
    },
  },
  myMessage: {
    alignSelf: "flex-end",
  },
  messagesWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "20px",
    flex: 1,
    overflowY: "auto",
  },
  formWrapper: {
    display: "flex",
  },
  chatRoomItem: {
    "& .MuiListItemButton-root": {
      width: "100%",
    },
  },
  emptyChat: {
    height: "calc(100vh - 65px)",
  },
  unReadRoom: {
    width: "8px",
    height: "8px",
    borderRadius: "4px",
    backgroundColor: `${theme.palette.secondary.main}`,
  },
});

const Chat = (props) => {
  const [user] = useAuthState(auth);
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [roomId, setRoomId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const messagesRef = useRef(null);

  // error message
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("success");

  const addMessage = useCallback(() => {
    if (newMessage.length && user && roomId) {
      db.collection("Chatrooms")
        .doc(roomId)
        .collection("Chats")
        .add({
          message: newMessage,
          time: getDartTimestamp(new Date()),
          sent_by: user.uid,
        })
        .then((response) => {
          setNewMessage("");
          db.collection("Chatrooms").doc(roomId).update({
            last_message: newMessage,
            admin_new_msg_status: true,
          });
        });
    }
  }, [user, newMessage, roomId]);

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        addMessage();
      }
    },
    [addMessage]
  );

  useEffect(() => {
    if (user) {
      db.collection("Chatrooms")
        .where("users", "array-contains", user.uid)
        .orderBy("updated_at", "desc")
        .onSnapshot((response) => {
          let data = response.docs;
          const chats = data.map((doc) => {
            return new ChatModel(doc.data());
          });
          setChats(chats);
        });
    }
  }, [user, roomId]);

  useEffect(() => {
    if (roomId && messagesRef) {
      db.collection("Chatrooms")
        .doc(roomId)
        .collection("Chats")
        .orderBy("time", "asc")
        .onSnapshot((response) => {
          let data = response.docs;
          const messages = data.map((doc) => {
            return new MessageModel(doc.id, doc.data());
          });
          setMessages(messages);
          messagesRef.current?.scrollIntoView({ behavior: "smooth" });
          db.collection("Chatrooms").doc(roomId).update({
            user_new_msg_status: false,
          });
        });
    }
  }, [roomId]);

  return (
    <Container>
      {chats.length ? (
        <Grid container alignItems="center" className={props.classes.chatPanel}>
          <Grid item xs={3} className={props.classes.leftPanel}>
            <List>
              {chats.map((chat) => (
                <ListItem
                  className={classNames(props.classes.chatRoomItem, {
                    [`${props.classes.activeRoom}`]: chat.roomId === roomId,
                  })}
                  key={chat.roomId}
                >
                  <ListItemButton onClick={() => setRoomId(chat.roomId)}>
                    <ListItemAvatar>
                      <Avatar
                        alt={chat.adminFirstName}
                        src={chat.adminImageURL}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={chat.adminFirstName + " " + chat.adminLastName}
                      secondary={
                        <React.Fragment>
                          <Typography noWrap>
                            {chat.lastMessage
                              ? ` — ${chat.lastMessage}`
                              : ` — ${chat.createdAt.toLocaleString()}`}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                  {chat.userNewMessageStatus ? (
                    <div className={props.classes.unReadRoom} />
                  ) : null}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={9} className={props.classes.rightPanel}>
            <div className={props.classes.messagesWrapper}>
              {messages.map((message) => (
                <Chip
                  className={classNames(props.classes.message, {
                    [`${props.classes.myMessage}`]:
                      message.sentBy === user?.uid,
                  })}
                  key={message.id}
                  label={message.message}
                  color={message.sentBy === user?.uid ? "primary" : "success"}
                />
              ))}
              <div ref={messagesRef} />
            </div>
            {roomId ? (
              <div className={props.classes.formWrapper}>
                <TextField
                  variant="outlined"
                  value={newMessage}
                  onChange={(event) => setNewMessage(event.target.value)}
                  fullWidth
                  onKeyPress={(event) => keyPress(event)}
                />
                <Button color="primary" onClick={addMessage}>
                  Send
                </Button>
              </div>
            ) : null}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={props.classes.emptyChat}
        >
          <Typography variant="h4">There is no active chat</Typography>
        </Grid>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={messageOpen}
        autoHideDuration={6000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert onClose={() => setMessageOpen(false)} severity={messageType}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withRouter(withStyles(styles)(Chat));
