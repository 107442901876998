import { createSlice } from "@reduxjs/toolkit";
import { userRoles } from "../../consts/userRoles";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    roleId: userRoles.USER,
  },
  reducers: {
    setRoleId: (state, action) => {
      state.roleId = action.payload;
    },
  },
});

export const { setRoleId } = userSlice.actions;
export default userSlice.reducer;
