const AppMenu = [
  {
    label: "Profile",
    pathname: "/profile",
  },
  {
    label: "Chat",
    pathname: "/chat",
  },
  {
    label: "Form",
    pathname: "/form",
  },
  /*{
    label: "File",
    pathname: "/file",
  },*/
  {
    label: "logout",
    pathname: "/logout",
  },
];

export default AppMenu;
