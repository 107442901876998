import { useState } from "react";
import { db } from "../helpers/firebase";

const initialFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  address: "",
  imageURL: "",
  formSubmitted: false,
  success: false,
};

const required = {
  firstName: "",
  lastName: "",
  email: "",
};

export const useFormControls = (uid) => {
  // We'll update "values" as the form updates
  const [values, setValues] = useState(initialFormValues);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("success");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    for (const [key, value] of Object.entries(fieldValues)) {
      if (key in required) temp[key] = value ? "" : "This field is required.";
    }

    if ("email" in fieldValues) {
      //temp.email = fieldValues.email ? "" : "This field is required."
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    setErrors({
      ...temp,
    });
  };
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formIsValid()) {
      console.log(values);
      db.collection("Users")
        .doc(uid)
        .update({
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          address: values.address || "",
          image_url: values.imageURL || "",
        })
        .then((data) => {
          setMessage("Profile updated successfully");
          setMessageType("success");
          setMessageOpen(true);
        })
        .catch(function (error) {
          setMessage(error.message);
          setMessageType("error");
          setMessageOpen(true);
        });
    }
  };
  const formIsValid = (fieldValues = values) => {
    return (
      fieldValues.firstName &&
      fieldValues.lastName &&
      fieldValues.email &&
      Object.values(errors).every((x) => x === "")
    );
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    values,
    setValues,
    errors,
    message,
    messageOpen,
    setMessageOpen,
    messageType,
  };
};
