import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../helpers/firebase";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faFilePdf,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Alert } from "@material-ui/lab";

const styles = (theme) => ({
  leftSide: {
    marginBottom: "20px",
  },
  container: {
    paddingTop: "40px",
  },
  heading: {
    textAlign: "left",
  },
  subtitle: {
    marginBottom: "20px",
  },
  uploadCard: {
    backgroundColor: `${theme.palette.primary.main}`,
  },
  uploadCardContent: {
    textAlign: "center",
    paddingTop: "30px",
  },
  uploadCardInfo: {
    backgroundColor: `${theme.palette.primary.main}`,
    marginTop: "10px",
  },
  uploadCardInfoContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  fileDataContainer: {
    flex: 1,
    overflow: "hidden",
  },
  fileName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginTop: "0",
    marginBottom: "5px",
  },
  cancelButton: {
    width: "30px",
    height: "30px",
  },
});

const VALID_FILE_SIZE = 5 * 1024 * 1024;

const FileUpload = (props) => {
  const [user] = useAuthState(auth);
  const [resumeFileURL, setResumeFileURL] = useState(null);
  //const [resumeStatus, setResumeStatus] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("success");

  useEffect(() => {
    db.collection("Users")
      .doc(user?.uid)
      .get()
      .then((response) => {
        let data = response.data();
        setResumeFileURL(data.resume_file_url ?? null);
        //setResumeStatus(data.resume_status ?? null);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [user]);

  const handleFileUpload = useCallback(
    ({ target }) => {
      const fileReader = new FileReader();

      if (target.files) {
        const file = target.files[0];
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
          if (file.type !== "application/pdf" || file.size > VALID_FILE_SIZE) {
            setMessage("File size or type is invalid");
            setMessageType("error");
            setMessageOpen(true);
            return;
          }
          setFileData(file);

          const fileRef = storage.ref().child(`file/${user.uid}.pdf`);
          const uploadTask = fileRef.put(file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setFileUploadProgress(progress);
            },
            (error) => {
              setMessage(error.message);
              setMessageType("error");
              setMessageOpen(true);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                setResumeFileURL(downloadURL);

                db.collection("Users")
                  .doc(user.uid)
                  .update({
                    resume_file_url: downloadURL,
                  })
                  .then((data) => {
                    setMessage("Resume submitted successfully");
                    setMessageType("success");
                    setMessageOpen(true);
                  })
                  .catch(function (error) {
                    setMessage(error.message);
                    setMessageType("error");
                    setMessageOpen(true);
                  });
              });
            }
          );
        };
      }
    },
    [user.uid]
  );

  const cancelFileUpload = useCallback(() => {
    setFileData(null);
    setFileUploadProgress(0);
  }, []);

  const bytesToMegaBytes = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        className={props.classes.container}
        direction="row"
        justifyContent="center"
      >
        <Grid item xs={12} sm={12} md={8} className={props.classes.leftSide}>
          <Typography variant="h4" className={props.classes.heading}>
            Resume File
          </Typography>
          <Typography variant="subtitle1" className={props.classes.subtitle}>
            Please upload your resume in "PDF" format
          </Typography>

          {resumeFileURL ? (
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                endIcon={<FontAwesomeIcon icon={faArrowCircleDown} />}
                href={resumeFileURL}
                target="_blank"
              >
                Download
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card className={props.classes.uploadCard}>
            <CardContent className={props.classes.uploadCardContent}>
              <FontAwesomeIcon icon={faFilePdf} size="5x" />
              <p>Maximum file size is {bytesToMegaBytes(VALID_FILE_SIZE)}</p>

              <input
                accept="application/pdf"
                type="file"
                hidden
                id="resume-file-input"
                onChange={handleFileUpload}
              />
              <label htmlFor="resume-file-input">
                <Button
                  disabled={!!fileUploadProgress}
                  size="large"
                  variant="outlined"
                  component="span"
                >
                  Select File
                </Button>
              </label>
            </CardContent>
          </Card>

          {fileData ? (
            <Card className={props.classes.uploadCardInfo}>
              <CardContent>
                <div className={props.classes.uploadCardInfoContent}>
                  <div className={props.classes.fileDataContainer}>
                    <p className={props.classes.fileName}>{fileData.name}</p>
                    <div>File size: {bytesToMegaBytes(fileData.size)}</div>
                  </div>
                  <IconButton
                    size="small"
                    className={props.classes.cancelButton}
                    onClick={cancelFileUpload}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </div>

                <LinearProgress
                  color="secondary"
                  variant="determinate"
                  value={fileUploadProgress}
                />
              </CardContent>
            </Card>
          ) : null}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={messageOpen}
        autoHideDuration={6000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert onClose={() => setMessageOpen(false)} severity={messageType}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withRouter(withStyles(styles)(FileUpload));
