import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { Link, withRouter } from "react-router-dom";
import appIcon from "../assets/images/app_icon.png";
import form from "../assets/images/form.png";
import mobile from "../assets/images/mobile.jpg";
import Button from "@material-ui/core/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebase";
import { withStyles } from "@material-ui/core";
import BottomMenu from "../components/ButtomMenu";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  greeting: {
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "100",
  },
  margin: {
    margin: theme.spacing(1),
  },
  mt20: {
    marginTop: "20px",
  },
  pt60: {
    paddingTop: "60px",
  },
  logo: {
    paddingBottom: "0 !important",
  },
  logoImage: {
    maxWidth: "100%",
  },
  linksWrapper: {
    marginTop: 20,
  },
  links: {
    margin: 5,
  },
  bottomMenu: {
    background: `${theme.palette.primary.dark}`,
    marginTop: "30px",
  },
  introBg: {
    position: "absolute",
    zIndex: 100,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: "rgba(0,0,0,.6)",
  },
  introContent: {
    position: "relative",
    zIndex: 101,
  },
  iconButton: {
    borderRadius: "6px !important",
    width: "64px",
    height: "64px",
    padding: 0,
    background: "#fff",
  },
  icon: {
    color: `${theme.palette.secondary.main}`,
  },
  featureTitle: {
    marginBottom: "5px",
  },
  mw100: {
    maxWidth: "100%",
  },
});

function Home(props) {
  const [user, error] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      props.history.push("/profile");
    }
  }, [error, props.history, user]);

  const greeting = useMemo(() => {
    const time = new Date().getHours();
    if (time >= 3 && time < 12) {
      return "Morning";
    } else if (time >= 12 && time < 17) {
      return "AfterNoon";
    } else if (time >= 17 && time < 21) {
      return "Evening";
    } else {
      return "Night";
    }
  }, []);

  return (
    <div className={props.classes.container}>
      <div id="intro">
        <div className={props.classes.introBg} />
        <div className={props.classes.introContent}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <p className={props.classes.greeting}>Good {greeting}</p>
            </Grid>
          </Grid>
          <Grid container alignContent="center" spacing={5}>
            <Grid item xs={4} sm={3} md={2} className={props.classes.logo}>
              <img
                className={props.classes.logoImage}
                src={appIcon}
                alt="app_icon"
              />
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <h1>Welcome to Transition</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>

              <Button
                className={props.classes.links}
                variant="outlined"
                color="default"
                size="large"
              >
                <Link to="/login">Login</Link>
              </Button>
              <Button
                className={props.classes.links}
                variant="outlined"
                color="default"
                size="large"
              >
                <Link to="/register">Join us</Link>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>

      <Grid container alignContent="center" justifyContent="center">
        <Grid item className={props.classes.mt20}>
          <Typography variant="h4" align="center">
            Section two title
          </Typography>
          <Typography variant="body1" align="center">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        className={props.classes.mt20}
        spacing={3}
      >
        <Grid item sm={12} md={5} className={props.classes.mt20}>
          {[1, 2, 3, 4, 5].map((item) => (
            <Grid
              key={`item-${item}`}
              container
              alignContent="center"
              className={props.classes.mt20}
            >
              <Grid item xs={2}>
                <Button
                  color="default"
                  variant="contained"
                  className={props.classes.iconButton}
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    size="2x"
                    className={props.classes.icon}
                  />
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" className={props.classes.featureTitle}>
                  Feature Title
                </Typography>
                <Typography variant="body2" color="default">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item sm={12} md={5} className={props.classes.mt20}>
          <img alt="form" src={form} className={props.classes.mw100} />
        </Grid>
      </Grid>

      <Grid
        container
        alignContent="center"
        justifyContent="center"
        className={props.classes.mt20}
      >
        <Grid item sm={12} md={5} className={props.classes.mt20}>
          <img alt="mobile" src={mobile} className={props.classes.mw100} />
        </Grid>
        <Grid item sm={12} md={5} className={props.classes.mt20}>
          <Typography variant="h4" className={props.classes.pt60}>
            Download our mobile App
          </Typography>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
          <div>
            <Button
              href="https://www.google.com"
              className={props.classes.links}
              variant="outlined"
              color="default"
              size="large"
            >
              Google Play
            </Button>
            <Button
              href="https://www.google.com"
              className={props.classes.links}
              variant="outlined"
              color="default"
              size="large"
            >
              Apple App Store
            </Button>
          </div>
        </Grid>
      </Grid>

      <Grid
        className={props.classes.bottomMenu}
        container
        justifyContent="center"
        alignContent="center"
        spacing={3}
      >
        {BottomMenu.map((menuItem, index) => (
          <Button
            key={`bottom-menu-item-${index}`}
            className={props.classes.links}
            color="default"
            size="large"
          >
            <Link to={menuItem.pathname}>{menuItem.label}</Link>
          </Button>
        ))}
      </Grid>
    </div>
  );
}

export default withRouter(withStyles(styles)(Home));
