import { useState } from "react";
import axios from "axios";
import { APP } from "../consts/app";
import { db } from "../helpers/firebase";
import { UserModel } from "../models";

const initialFormValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
  formSubmitted: false,
  success: false,
};

export const useFormControls = () => {
  // We'll update "values" as the form updates
  const [values, setValues] = useState(initialFormValues);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = useState({});
  const [responseError, setResponseError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    for (const [key, value] of Object.entries(fieldValues)) {
      temp[key] = value ? "" : "This field is required.";
    }

    if ("email" in fieldValues) {
      //temp.email = fieldValues.email ? "" : "This field is required."
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    setErrors({
      ...temp,
    });
  };
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        Token: APP.apiKey,
      },
    };

    //send email to all admins
    if (formIsValid()) {
      setLoading(true);
      db.collection("Users")
        .where("role_id", "==", 10)
        .get()
        .then((response) => {
          let data = response.docs;
          const admins = data.map((doc) => {
            let admin = new UserModel(doc.data());
            admin.uID = doc.id;
            return admin;
          });

          const message =
            "Name and Family:" +
            values.name +
            "\n" +
            "Email:" +
            values.email +
            "\n" +
            "Phone: " +
            values.phone +
            "\n" +
            "Message: " +
            values.message;

          admins.forEach(async (admin) => {
            axios
              .post(
                APP.appURL + "/sendEmail",
                {
                  email: admin.email,
                  message: message,
                  subject: "Contact Email",
                },
                config
              )
              .then((response) => {
                setMessageSent(true);
              })
              .catch((error) => {
                setResponseError(error.message);
                setErrorOpen(true);
              })
              .finally(() => {
                setLoading(false);
              });
          });
        })
        .catch((error) => {
          setResponseError(error.message);
          setErrorOpen(true);
        });
    }
  };
  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.name &&
      fieldValues.email &&
      fieldValues.phone &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    responseError,
    errorOpen,
    setErrorOpen,
    messageSent,
    setMessageSent,
    loading,
  };
};
