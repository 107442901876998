import React, { useEffect } from "react";
import { useFormControls } from "../validations/register";
import { Link, withRouter } from "react-router-dom";
import {
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../helpers/firebase";
import { setRoleId } from "../plugins/store/userSlice";
import { useDispatch } from "react-redux";
import BottomMenu from "../components/ButtomMenu";

const styles = (theme) => ({
  container: {
    minHeight: "calc(100vh - 65px)",
    display: "flex",
    flexDirection: "column",
  },
  bottomMenu: {
    background: `${theme.palette.primary.dark}`,
    marginTop: "auto",
  },
  field: {
    marginBottom: "25px",
  },
  heading: {
    textAlign: "center",
    margin: "40px 0",
  },
  mt10: {
    marginTop: "10px",
    display: "block",
  },
});

const inputFieldValues = [
  {
    name: "firstName",
    label: "First Name",
    id: "first-name",
  },
  {
    name: "lastName",
    label: "Last Name",
    id: "last-name",
  },
  {
    name: "email",
    label: "Email",
    id: "email",
  },
  {
    name: "password",
    label: "Password",
    id: "password",
    type: "password",
  },
  {
    name: "repeatPassword",
    label: "Repeat Password",
    id: "repeat-password",
    type: "password",
  },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Register = (props) => {
  const [user] = useAuthState(auth);

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    registerError,
    errorOpen,
    setErrorOpen,
    registered,
  } = useFormControls();
  const dispatch = useDispatch();

  useEffect(() => {
    if (registered || user) {
      db.collection("Users")
        .doc(user?.uid)
        .get()
        .then((response) => {
          let data = response.data();
          dispatch(setRoleId(data.role_id));

          props.history.push("/login");
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [dispatch, props.history, registered, user]);

  return (
    <div className={props.classes.container}>
      <Container maxWidth="md">
        <Grid container direction="row" justifyContent="center">
          <Grid item md={7}>
            <Typography variant="h5" className={props.classes.heading}>
              Register a new account
            </Typography>
          </Grid>
          <Grid item md={7}>
            <form
              className={props.classes.form}
              onSubmit={handleFormSubmit}
              noValidate
              autoComplete="off"
            >
              {inputFieldValues.map((inputFieldValue, index) => {
                return (
                  <TextField
                    key={index}
                    onBlur={handleInputValue}
                    onChange={handleInputValue}
                    fullWidth
                    name={inputFieldValue.name}
                    label={inputFieldValue.label}
                    className={props.classes.field}
                    variant="outlined"
                    type={inputFieldValue.type ?? "text"}
                    {...(errors[inputFieldValue.name] && {
                      error: true,
                      helperText: errors[inputFieldValue.name],
                    })}
                  />
                );
              })}

              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={props.classes.margin}
                disabled={!formIsValid()}
              >
                Login
              </Button>
            </form>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          className={props.classes.heading}
        >
          <Grid item xs={12}>
            Do you have an account?
          </Grid>
          <Grid item xs={12}>
            <Link className={props.classes.mt10} to="/login">
              Login Now
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Grid
        className={props.classes.bottomMenu}
        container
        justifyContent="center"
        alignContent="center"
        spacing={3}
      >
        {BottomMenu.map((menuItem, index) => (
          <Button
            key={`bottom-menu-item-${index}`}
            className={props.classes.links}
            color="default"
            size="large"
          >
            <Link to={menuItem.pathname}>{menuItem.label}</Link>
          </Button>
        ))}
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          {registerError}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withRouter(withStyles(styles)(Register));
