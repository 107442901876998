import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Grid,
  Container,
  TextField,
  Typography,
  Snackbar,
  withStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useFormControls } from "../validations/login";
import MuiAlert from "@material-ui/lab/Alert";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../helpers/firebase";
import { useDispatch, useSelector } from "react-redux";
import { setRoleId } from "../plugins/store/userSlice";
import { userRoles } from "../consts/userRoles";
import BottomMenu from "../components/ButtomMenu";

const styles = (theme) => ({
  container: {
    minHeight: "calc(100vh - 65px)",
    display: "flex",
    flexDirection: "column",
  },
  bottomMenu: {
    background: `${theme.palette.primary.dark}`,
    marginTop: "auto",
  },
  heading: {
    textAlign: "center",
    margin: "40px 0",
  },
  form: {
    width: "100%",
  },
  field: {
    marginBottom: "25px",
  },
  mt10: {
    marginTop: "10px",
    display: "block",
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Login = (props) => {
  const [user] = useAuthState(auth);
  const roleId = useSelector((state) => state.user?.roleId);
  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    loginError,
    errorOpen,
    setErrorOpen,
    loggedIn,
  } = useFormControls();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      db.collection("Users")
        .doc(user?.uid)
        .get()
        .then((response) => {
          let data = response.data();
          dispatch(setRoleId(data.role_id));
          if (data.role_id === userRoles.ADMIN) {
            props.history.push("/users");
          } else {
            props.history.push("/form");
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [dispatch, loggedIn, props.history, user]);

  useEffect(() => {
    if (user && roleId) {
      if (roleId === userRoles.ADMIN) {
        props.history.push("/users");
      } else {
        props.history.push("/form");
      }
    }
  }, [props.history, roleId, user]);

  return (
    <div className={props.classes.container}>
      <Container maxWidth="md">
        <Grid container direction="row" justifyContent="center">
          <Grid item sm={12} md={7}>
            <Typography variant="h5" className={props.classes.heading}>
              Login to your account
            </Typography>
          </Grid>
          <Grid item sm={12} md={7}>
            <form
              className={props.classes.form}
              onSubmit={handleFormSubmit}
              noValidate
              autoComplete="off"
            >
              <TextField
                name="email"
                fullWidth
                label="Email"
                className={props.classes.field}
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["email"] && {
                  error: true,
                  helperText: errors["email"],
                })}
              />
              <TextField
                name="password"
                type="password"
                fullWidth
                label="Password"
                className={props.classes.field}
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["password"] && {
                  error: true,
                  helperText: errors["password"],
                })}
              />
              <Button
                fullWidth
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                className={props.classes.margin}
                disabled={!formIsValid()}
              >
                Login
              </Button>
            </form>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          className={props.classes.heading}
        >
          <Grid item xs={12}>
            Don't have an account?
          </Grid>
          <Grid item xs={12}>
            <Link className={props.classes.mt10} to="/register">
              Register Now
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          className={props.classes.heading}
        >
          <Grid item xs={12}>
            Forget your password?
          </Grid>
          <Grid item xs={12}>
            <Link className={props.classes.mt10} to="/rest-password">
              Reset Now
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Grid
        className={props.classes.bottomMenu}
        container
        justifyContent="center"
        alignContent="center"
        spacing={3}
      >
        {BottomMenu.map((menuItem, index) => (
          <Button
            key={`bottom-menu-item-${index}`}
            className={props.classes.links}
            color="default"
            size="large"
          >
            <Link to={menuItem.pathname}>{menuItem.label}</Link>
          </Button>
        ))}
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          {loginError}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withRouter(withStyles(styles)(Login));
